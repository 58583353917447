import { AccredibleAccountsRedirectionService } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import { isUuidSlashEditRoute } from '@accredible-frontend-v2/utils/credential-id-helper';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SSOAuthenticatedGuard implements CanActivate {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router,
    private _userApi: AccredibleUserApiService,
    private _accountsRedirection: AccredibleAccountsRedirectionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): UrlTree | boolean {
    if (this._userApi.isUserSignedIn()) {
      if (isUuidSlashEditRoute(this._document.location.pathname)) {
        // Go to credential
        const credentialIdOrUuid = this._document.location.pathname.split('/')[1];
        return this._router.createUrlTree([credentialIdOrUuid]);
      } else {
        // Go to user's wallet
        return this._router.createUrlTree(['profile', this._userApi.getUser().username]);
      }
    } else {
      // SSO authentication failed
      if (route.queryParams.return_to) {
        // Go to jwt-expired route
        return this._router.createUrlTree(['jwt-expired'], {
          queryParams: { return_to: route.queryParams.return_to },
        });
      } else {
        this._document.location.href = this._accountsRedirection.getLoginUrlOrigin();
      }
    }

    return false;
  }
}
