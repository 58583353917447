import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { Injectable, Optional } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';
import { DefaultToastComponent } from '../components/default-toast/default-toast.component';
import { AccredibleToastData } from '../model/toast.model';
import { AccredibleToastConfig } from '../toast.config';

@Injectable({
  providedIn: 'root',
})
export class AccredibleToastService {
  private readonly _config = new AccredibleToastConfig();
  private _snackBarConfig = new MatSnackBarConfig();

  constructor(
    private readonly _matSnackBar: MatSnackBar,
    private readonly _language: AccredibleLanguageService,
    @Optional() config?: AccredibleToastConfig,
  ) {
    if (config) {
      this._config = {
        ...this._config,
        ...config,
      };
    }
  }

  info(
    message: string,
    action: string = this._language.translate('default-toast.ok'),
    duration = this._config.duration,
  ): void {
    this._snackBarConfig.announcementMessage = `${this._language.translate(
      'default-toast.info_alert',
    )} ${message}`;
    this._snackBarConfig.panelClass = ['default-toast-info'];
    this._snackBarConfig.duration = duration;
    this._snackBarConfig.data = <AccredibleToastData>{
      message: message,
      action: action,
    };

    this._openDefaultFromComponent();
  }

  success(
    message: string,
    action: string = this._language.translate('default-toast.ok'),
    duration = this._config.duration,
  ): void {
    this._snackBarConfig.announcementMessage = `${this._language.translate(
      'default-toast.success_alert',
    )} ${message}`;
    this._snackBarConfig.panelClass = ['default-toast-success'];
    this._snackBarConfig.duration = duration;
    this._snackBarConfig.data = <AccredibleToastData>{
      message: message,
      icon: 'check_circle',
      action: action,
    };

    this._openDefaultFromComponent();
  }

  error(
    message: string,
    action: string = this._language.translate('default-toast.ok'),
    duration = this._config.errorDuration,
  ): void {
    this._snackBarConfig.announcementMessage = `${this._language.translate(
      'default-toast.error_alert',
    )} ${message}`;
    this._snackBarConfig.panelClass = ['default-toast-error'];
    this._snackBarConfig.duration = duration;
    this._snackBarConfig.data = <AccredibleToastData>{
      message: message,
      icon: 'warning',
      action: action,
    };

    this._openDefaultFromComponent();
  }

  dismissCurrentToast(): void {
    this._matSnackBar?.dismiss();
  }

  private _openDefaultFromComponent(): void {
    /**
     * @Workaround
     * setTimeout is needed in order for screen reader to be able to read the text from the snackbar
     * when this one appears after closing a dialog.
     *
     * TODO: There should be a better solution for this (maybe using rxjs)
     */
    setTimeout(() => {
      this._matSnackBar.openFromComponent(DefaultToastComponent, this._snackBarConfig);
    }, 100);
  }
}
