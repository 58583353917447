export interface ApiError extends ApiErrorBase {
  errors?: ApiErrorDetails[];
}

export interface ApiErrorDetails {
  property: string;
  reason: string;
  metadata?: any;
}

export interface ApiErrorBase {
  code: number;
  message: string;
  status: string;
}
