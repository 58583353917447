<ng-container *ngIf="showCookiesBanner">
  <ng-container *transloco="let t; read 'cookies-banner'">
    <div #cookiesBanner
      role="region"
      class="cookies-banner"
      [style.visibility]="isRecipientPortal ? 'hidden' : 'unset'"
      [attr.aria-label]="t('aria_privacy_information')">
      <div class="content cf-content-horizontal">
        <span>
          {{ t('consent_message') }}
          <a href="https://cookiesandyou.com/"
            target="_blank">{{ t('learn_more') }}</a>
        </span>

        <button *ngIf="hasMatTheme; else newThemeButton"
          mat-raised-button
          color="accent"
          class="outline-alt"
          (click)="dismiss();">
          {{ t('dismiss') }}
        </button>

        <ng-template #newThemeButton>
          <button accredible-button
            [color]="'natural'"
            [size]="'small'"
            (click)="dismiss();">
            {{ t('dismiss') }}
          </button>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>
