import { AccrediblePageData, AccrediblePageParams } from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MyLearningPathwayEnrolment } from '../../models/my-learning.model';
import { RecipientPortalApiService } from '../rp-api/rp-api.service';

@Injectable({
  providedIn: 'root',
})
export class MyLearningApiService extends RecipientPortalApiService {
  loadMyLearningPathwayEnrolments(
    username: string,
    pageParams: AccrediblePageParams,
  ): Observable<AccrediblePageData<MyLearningPathwayEnrolment>> {
    const queryParams = `page_size=${pageParams.page_size}&page=${pageParams.page}`;
    const url = `/v1/recipient/users/${username}/learning/pathways/search?${queryParams}`;

    return this._post(url, {}).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }
}
