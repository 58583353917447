<div *transloco="let t; read: 'default-toast'"
  class="default-toast-container">

  <div class="message-container">
    <mat-icon class="alert-icon trailing-margin">{{ toastData.icon }}</mat-icon>

    <span class="message"
      [innerHTML]="toastData.message">
    </span>
  </div>

  <div class="action-container">
    <button mat-icon-button
      [attr.aria-label]="t('close_alert')"
      (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

</div>
