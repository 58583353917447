<ng-container *transloco="let t; read: 'dialog-template'">
  <div *ngIf="(dialogTitle || confirmDialogTitle) && !disableClose"
    class="header">

    <h2 *ngIf="dialogTitle || confirmDialogTitle"
      mat-dialog-title
      [innerHTML]="dialogTitle || confirmDialogTitle">
    </h2>
  </div>

  <ng-container *ngIf="!formGroup; else formContainer">
    <ng-container *ngTemplateOutlet="dialogContentAndActions"></ng-container>
  </ng-container>

  <ng-template #formContainer>
    <form [formGroup]="formGroup"
      (ngSubmit)="btnActionClick.emit()"
      autocomplete="off">
      <ng-container *ngTemplateOutlet="dialogContentAndActions"></ng-container>
    </form>
  </ng-template>

  <ng-template #dialogContentAndActions>
    <mat-dialog-content>
      <ng-content></ng-content>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="showActions"
      [align]="alignActions">
      <button mat-stroked-button
        data-cy="btn-dialog-cancel"
        type="button"
        class="mat-default"
        [class.trailing-margin]="!!formGroup"
        (click)="onCancelBtnClick()">
        {{ btnCancelText || t('cancel') }}
      </button>

      <button *ngIf="!formGroup"
        mat-flat-button
        data-cy="btn-dialog-action"
        type="button"
        [color]="btnActionColor"
        [disabled]="disableBtnAction"
        (click)="btnActionClick.emit()">
        {{ btnActionText }}
      </button>

      <accredible-button-loading *ngIf="!!formGroup"
        dataCy="btn-dialog-action"
        btnType="submit"
        [isLoading]="isSavingForm"
        [btnText]="btnActionText"
        [disableBtn]="disableBtnAction">
      </accredible-button-loading>
    </mat-dialog-actions>
  </ng-template>

  <div *ngIf="!showActions || !disableClose"
    class="close-dialog">
    <button mat-icon-button
      [attr.aria-label]="t('close_dialog')"
      (click)="onCancelBtnClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-container>

