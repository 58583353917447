<div class="navigation-container">
  <button mat-icon-button
    matTooltip="Go to previous page"
    aria-label="Go to previous page"
    [disabled]="isPreviousDisabled"
    (click)="goToPreviousPage()">
    <i class="arrow left"></i>
  </button>

  <button mat-icon-button
    matTooltip="Go to next page"
    aria-label="Go to next page"
    [disabled]="isNextDisabled"
    (click)="goToNextPage()">
    <i class="arrow right"></i>
  </button>

  <!-- TODO: Might want to remove this later -->
  <!--  <button mat-icon-button-->
  <!--    matTooltip="Refresh page"-->
  <!--    aria-label="Refresh page"-->
  <!--    (click)="refreshPage()">-->
  <!--    <i class="arrow refresh"></i>-->
  <!--  </button>-->
</div>
