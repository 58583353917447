export enum AccredibleIconType {
  MAT = 'mat',
  ACC = 'acc',
  SVG = 'svg',
  NEW_ACC = 'new-acc',
}

export interface AccredibleIcon {
  type: AccredibleIconType;
  name: string;
  // the only supported fontSet atm is 'material-icons-outlined',
  // if we need to support more see https://stackoverflow.com/questions/50303454/how-to-use-the-new-material-design-icon-themes-outlined-rounded-two-tone-and
  fontSet?: 'material-icons-outlined';
  size?: number;
  rtlMirror?: boolean;
}
