export interface ThemeHelperInterface extends BaseThemeHelperInterface {
  getTheme: GetTheme;
}

export interface GetTheme {
  (themeOverride?: string): string;
}

export interface BaseThemeHelperInterface {
  setThemeFavicon: SetThemeFavicon;
  loadThemeStyles: LoadThemeStyles;
}

export interface SetThemeFavicon {
  (theme: string): void;
}

export interface LoadThemeStyles {
  (themeName: string): Promise<string>;
}
