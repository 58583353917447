import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { WhitelabelHelper } from '../../../shared/helpers/whitelabel.helper';
import { CredentialsState } from '../../../shared/stores/credentials/credentials.reducer';
import { selectOrganizationSettings } from '../../../shared/stores/credentials/credentials.selectors';

@Injectable({
  providedIn: 'root',
})
export class IssuerPageGuard implements CanActivate {
  constructor(
    private _credentialsStore: Store<CredentialsState>,
    private _router: Router,
    private _location: Location,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const issuerId = +route.params.id;
    // Prevent other issuer's pages from being shown on white labelled domains.
    // Ignores localhost and credential.net aliases in order to be able to test any issuer's group in any theme when developing.
    if (WhitelabelHelper.isCustomDomain()) {
      return this._credentialsStore.select(selectOrganizationSettings).pipe(
        first((organizationSettings) => !!organizationSettings.department_id),
        map((organizationSettings) => {
          if (issuerId !== organizationSettings.department_id) {
            this._router.navigate(['404']).then(() => {
              this._location.replaceState(state.url);
            });
            return false;
          }
          return true;
        }),
      );
    }
    return true;
  }
}
