import { AccrediblePageData, AccrediblePageParams } from '@accredible-frontend-v2/models';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { MyLearningPathwayEnrolment } from '../../models/my-learning.model';
import { loadMyLearningPathwayEnrolments } from '../../stores/my-learning-pathway-enrolments/my-learning-pathway-enrolments.actions';
import { MyLearningPathwayEnrolmentsState } from '../../stores/my-learning-pathway-enrolments/my-learning-pathway-enrolments.reducer';
import {
  selectMyLearningFetchedPageParams,
  selectMyLearningPathwayEnrolmentsPageData,
} from '../../stores/my-learning-pathway-enrolments/my-learning-pathway-enrolments.selectors';

@Injectable({
  providedIn: 'root',
})
export class MyLearningFacadeService {
  constructor(
    private readonly _myLearningPathwayEnrolmentsStore: Store<MyLearningPathwayEnrolmentsState>,
  ) {}

  myLearningPathwayEnrolmentsPageData$ = this._myLearningPathwayEnrolmentsStore.select(
    selectMyLearningPathwayEnrolmentsPageData,
  );

  myLearningFetchedPageParams$ = this._myLearningPathwayEnrolmentsStore.select(
    selectMyLearningFetchedPageParams,
  );

  getMyLearningPathwayEnrolments(
    username: string,
    pageParamsToFetch: AccrediblePageParams = {
      page: 1,
      page_size: WindowHelper.numberOfRowsThatFitOnScreen(150),
    },
  ): Observable<AccrediblePageData<MyLearningPathwayEnrolment>> {
    return combineLatest([
      this.myLearningPathwayEnrolmentsPageData$,
      this.myLearningFetchedPageParams$,
    ]).pipe(
      tap(([myLearningPathwayEnrolmentsPageData, pageParams]) =>
        this.fetchEnrolments(
          myLearningPathwayEnrolmentsPageData,
          pageParams,
          username,
          pageParamsToFetch,
        ),
      ),
      map(([myLearningPathwayEnrolmentsPageData]) => myLearningPathwayEnrolmentsPageData),
      first(({ pathway_enrolments }) => pathway_enrolments !== null),
    );
  }

  private fetchEnrolments(
    myLearningPathwayEnrolmentsPageData: AccrediblePageData<MyLearningPathwayEnrolment>,
    pageParams: AccrediblePageParams,
    username: string,
    pageParamsToFetch: AccrediblePageParams,
  ): void {
    const { pathway_enrolments } = myLearningPathwayEnrolmentsPageData;

    if (
      pathway_enrolments === null ||
      (pageParams !== null && !isEqual(pageParams, pageParamsToFetch))
    ) {
      this._myLearningPathwayEnrolmentsStore.dispatch(
        loadMyLearningPathwayEnrolments({ username, pageParams: pageParamsToFetch }),
      );
    }
  }
}
