import { AccrediblePageData, AccrediblePageParams } from '@accredible-frontend-v2/models';
import { Action, createReducer, on } from '@ngrx/store';
import { MyLearningPathwayEnrolment } from '../../models/my-learning.model';
import * as MyLearningPathwayEnrolmentsActions from './my-learning-pathway-enrolments.actions';

export const myLearningPathwayEnrolmentsFeatureKey = 'myLearningPathwayEnrolments';

export interface MyLearningPathwayEnrolmentsState {
  myLearningPathwayEnrolmentsPageData: AccrediblePageData<MyLearningPathwayEnrolment>;
  pageParams: AccrediblePageParams;

  action: myLearningPathwayEnrolmentsStateAction;
  payload: any;
  error: any;
}

export enum myLearningPathwayEnrolmentsStateAction {
  NO_ACTION,
  MY_LEARNING_PATHWAY_ENROLMENTS_LOADED,
  HAS_ERROR,
}

const initialStateHandling: Partial<MyLearningPathwayEnrolmentsState> = {
  action: myLearningPathwayEnrolmentsStateAction.NO_ACTION,
  payload: null,
  error: null,
};

export const initialState: MyLearningPathwayEnrolmentsState = {
  myLearningPathwayEnrolmentsPageData: {
    meta: {},
    pathway_enrolments: null,
  },
  pageParams: null,
  ...(<MyLearningPathwayEnrolmentsState>initialStateHandling),
};

const myLearningPathwayEnrolmentsReducer = createReducer(
  initialState,
  on(MyLearningPathwayEnrolmentsActions.loadMyLearningPathwayEnrolments, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(
    MyLearningPathwayEnrolmentsActions.loadMyLearningPathwayEnrolmentsSuccess,
    (state, { myLearningPathwayEnrolmentsPageData, pageParams }) => ({
      ...state,
      myLearningPathwayEnrolmentsPageData,
      pageParams,
      action: myLearningPathwayEnrolmentsStateAction.MY_LEARNING_PATHWAY_ENROLMENTS_LOADED,
    }),
  ),
  on(
    MyLearningPathwayEnrolmentsActions.loadMyLearningPathwayEnrolmentsFailure,
    (state, { error }) => ({
      ...state,
      action: myLearningPathwayEnrolmentsStateAction.HAS_ERROR,
      error,
    }),
  ),
);

export function reducer(
  state: MyLearningPathwayEnrolmentsState,
  action: Action,
): MyLearningPathwayEnrolmentsState {
  return myLearningPathwayEnrolmentsReducer(state, action);
}
