export enum LearningCategory {
  ACCOUNTING_AND_FINANCE = 'accounting_and_finance',
  BUSINESS = 'business',
  CREATIVITY = 'creativity',
  EDUCATION_AND_TRAINING = 'education_and_training',
  ENGINEERING_AND_MANUFACTURING = 'engineering_and_manufacturing',
  HEALTHCARE_AND_WELLNESS = 'healthcare_and_wellness',
  LAW_AND_GOVERNMENT = 'law_and_government',
  MARKETING_AND_SALES = 'marketing_and_sales',
  PERSONAL_DEVELOPMENT = 'personal_development',
  SCIENCE_AND_ENVIRONMENT = 'science_and_environment',
  TECHNOLOGY = 'technology',
  OTHER = 'other',
}

export enum LearningGoal {
  COURSE = 'course',
  MICRO_CREDENTIAL = 'micro_credential',
  CERTIFICATE = 'certificate',
  COLLECTION = 'collection',
  LICENSE = 'license',
  DEGREE = 'degree',
}
