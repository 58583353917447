import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RecipientPortalLoadService } from './recipient-portal-load.service';

export function initApp(appLoadService: RecipientPortalLoadService): () => Promise<boolean> {
  return (): Promise<boolean> => appLoadService.initializeApp();
}

export function validateSessionToken(
  appLoadService: RecipientPortalLoadService,
): () => Promise<boolean> {
  return (): Promise<boolean> => appLoadService.validateAuthenticationToken();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    RecipientPortalLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [RecipientPortalLoadService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: validateSessionToken,
      deps: [RecipientPortalLoadService],
      multi: true,
    },
  ],
})
export class RecipientPortalLoadModule {}
