import { AccredibleCheckboxInputComponentModule } from '@accredible-frontend-v2/forms/checkbox-input';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleConfirmDialogTemplateComponent } from '../confirm-dialog-template/confirm-dialog-template.component';
import { AccredibleDialogTemplateComponentModule } from '../dialog-template/dialog-template.component.module';
import { AccredibleConfirmDialogComponent } from './confirm-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    AccredibleDialogTemplateComponentModule,
    AccredibleConfirmDialogTemplateComponent,
    AccredibleCheckboxInputComponentModule,
  ],
  declarations: [AccredibleConfirmDialogComponent],
})
export class AccredibleConfirmDialogComponentModule {}
