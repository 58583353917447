export interface AccredibleGeolocation {
  ip: string;
  type: string;
  continent_code: string;
  continent_name: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip: string;
  latitude: number;
  longitude: number;
  location: Location;
  time_zone: TimeZone;
  currency: Currency;
  connection: Connection;
}

export interface AccredibleGeoViewport {
  northeast: AccredibleGeoCoordinates;
  southwest: AccredibleGeoCoordinates;
}

export interface AccredibleGeoCoordinates {
  lat: number;
  lng: number;
}

interface Connection {
  asn: number;
  isp: string;
}

interface Currency {
  code: string;
  name: string;
  plural: string;
  symbol: string;
  symbol_native: string;
}

interface Location {
  geoname_id: number;
  capital: string;
  languages: Language[];
  country_flag: string;
  country_flag_emoji: string;
  country_flag_emoji_unicode: string;
  calling_code: string;
  is_eu: boolean;
}

interface Language {
  code: string;
  name: string;
  native: string;
  rtl: number;
}

interface TimeZone {
  id: string;
  current_time: string;
  gmt_offset: number;
  code: string;
  is_daylight_saving: boolean;
}
