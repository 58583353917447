export interface CustomHeaderLink {
  label: string;
  link: string;
}

export interface HeaderConfiguration {
  color: string;
  textColor: string;
  height: number;
  boxShadow: string;
  textSize: number;
  textWeight: number;
  logoUrl: string;
  logoWidth: number;
  logoHeight: number;
}

export interface FooterConfiguration {
  color: string;
  textColor: string;
  logoUrl: string;
  logoWidth: number;
  logoHeight: number;
}

export interface MainHeaderBaseConfiguration {
  color: string;
  textColor: string;
  border: string;
}

export interface MainHeaderButtonConfiguration extends MainHeaderBaseConfiguration {}

export interface MainHeaderConfiguration extends MainHeaderBaseConfiguration {
  height: number;
  button: MainHeaderButtonConfiguration;
}

export interface HeaderFooterConfiguration {
  header: HeaderConfiguration;
  mainHeader: MainHeaderConfiguration;
  footer: FooterConfiguration;
  customHeaderLinks: CustomHeaderLink[];
}
