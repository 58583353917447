import { AccredibleUser } from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AccredibleUserApiService } from './user.service';

@Injectable()
export class AccredibleUserApiServiceMock extends AccredibleUserApiService {
  isUserSignedIn(): boolean {
    return false;
  }

  getUser(): AccredibleUser {
    return <AccredibleUser>{ id: 12212 };
  }

  logout(): Observable<boolean> {
    return of(true);
  }
}
