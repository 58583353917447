import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleBaseThemeHelper } from '@accredible-frontend-v2/utils/base-theme-helper';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { credentialNetAliases } from '../shared/helpers/whitelabel.helper';

export class ThemeHelper extends AccredibleBaseThemeHelper {
  static getTheme(themeOverride?: string): string {
    if (themeOverride) {
      const themeExists = !!accredibleCustomThemesMetadata[themeOverride];

      if (environment.theming) {
        // If we are on theme preview we don't want to check the CustomThemesMetadata file
        return themeExists ? themeOverride : 'default.recipient-portal.theme';
      }

      return themeExists ? themeOverride : 'credential.net';
    } else {
      const appDomain = window.location.hostname;
      return credentialNetAliases.includes(appDomain) ||
        WindowHelper.isLocalhost() ||
        appDomain.startsWith('192.')
        ? environment.theme || 'credential.net'
        : this._getCustomTheme(appDomain);
    }
  }

  /**
   * Find the theme for the given appDomain on accredibleCustomThemesMetadata or default to 'credential.net'.
   *
   * Loop through all the themes on accredibleCustomThemesMetadata,
   * if there's a url in the recipientPortalUrls array that matches the appDomain, the key is the theme.
   * If we don't find a matching url default to 'credential.net'.
   */
  private static _getCustomTheme(appDomain: string): string {
    let customTheme = 'credential.net';

    Object.keys(accredibleCustomThemesMetadata).forEach((key) => {
      if (
        accredibleCustomThemesMetadata[key].app === 'recipient-portal' &&
        accredibleCustomThemesMetadata[key].recipientPortalUrls?.find((url) => url === appDomain)
      ) {
        customTheme = key;
        return;
      }
    });

    return customTheme;
  }
}
