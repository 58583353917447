import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * Usage example with params to override default values
 *
 * <accredible-icon [icon]="'chevron-down'"
 *   [@rotateAnimation]="{value: isOpen ? 'rotated': 'initial',  params: { start: '0', end: '90' }}">
 * </accredible-icon>
 */

export const rotateAnimation = trigger('rotateAnimation', [
  state(
    'initial',
    style({
      transform: 'rotate({{ start }}deg)',
    }),
    { params: { start: '0' } },
  ),
  state(
    'rotated',
    style({
      transform: 'rotate({{ end }}deg)',
    }),
    { params: { end: '180' } },
  ),
  transition('initial <=> rotated', animate('0.3s')),
]);
