import { Injectable } from '@angular/core';

type BrowserStorage = 'localStorage' | 'sessionStorage';

/**
 * NOTE: `localStorage`, `sessionStorage` and `window` are set to type 'any' to allow for testing
 */
@Injectable({
  providedIn: 'root',
})
export class AccredibleBrowserStorageService {
  private readonly localStorage: any = {};
  private readonly sessionStorage: any = {};

  get(key: string, storage: BrowserStorage = 'localStorage'): string {
    try {
      return (window as any)[storage].getItem(key);
    } catch (e) {
      // If cookies are disabled
      return this[storage][key];
    }
  }

  set(key: string, value: string, storage: BrowserStorage = 'localStorage'): void {
    try {
      window[storage].setItem(key, value);
    } catch (e) {
      // If cookies are disabled
      this[storage][key] = value;
    }
  }

  delete(key: string, storage: BrowserStorage = 'localStorage'): void {
    try {
      window[storage].removeItem(key);
    } catch (e) {
      // If cookies are disabled
      this[storage][key] = false;
    }
  }

  deleteMultiple(keys: string[], storage: BrowserStorage = 'localStorage'): void {
    keys.forEach((key: string) => this.delete(key, storage));
  }
}
