import { PathwayVersion } from './pathways.model';

export interface PathwayEnrolment {
  uuid: string;
  progress_percentage: number;
  completed_at: string;
  created_at: string;
  pathway?: {
    uuid: string;
    pathway_version: PathwayVersion;
    pathway_sequences: PathwaySequenceEnrolment[];
  };
  updated_at: string;
  pathway_enrolment_nodes: PathwayEnrolmentNode[];
}

export interface PathwaySequenceEnrolment {
  uuid: string;
  name: string;
  pathways: {
    uuid: string;
    pathway_version: PathwayVersionSequence[];
  };
}

export interface PathwayVersionSequence {
  uuid: string;
  name: string;
}

export interface PathwayEnrolmentNode {
  completed_at: string;
  credential: PathwayEnrolmentCredential;
  pathway_enrolment_node_items: PathwayEnrolmentNodeItem[];
  pathway_node_uuid: string;
  progress_percentage: number;
  uuid: string;
}

export interface PathwayEnrolmentNodeItem {
  completed_at: string;
  credential: PathwayEnrolmentCredential;
  pathway_node_item_uuid: string;
  uuid: string;
}

export interface PathwayEnrolmentCredential {
  group_id: number;
  name: string;
  url: string;
  uuid: string;
}
