import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'accredible-iframe-navigation',
  templateUrl: './iframe-navigation.component.html',
  styleUrls: ['./iframe-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleIframeNavigationComponent implements OnInit {
  @Input()
  preview = 'recipientPortalUrl';

  isPreviousDisabled = true;
  isNextDisabled = true;
  history: string[] = [];

  private _isIframeNavigation = false; // use this to check if we need to add to history or not
  private _currentUrlIndexInHistory = 0;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _router: Router,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  /**
   * Removes the 'theme' query param which causes preview css rebuild
   *
   * passing a URL of the form:
   * - '/<credential-uuid>?theme=<themeId>&p=name' returns '/<credential-uuid>?&p=name' and
   * - '/<credential-uuid>?theme=<themeId> returns '/<credential-uuid>'
   *
   * @param url
   * @private
   */
  private static _getFormattedHistoryUrl(url: string): string {
    const replacerFn = (match: string, pattern1: string, pattern2: string) => {
      return pattern1 ? `?` : '';
    };

    return url.replace(/(\?theme(?:\w|\W)+&)|(\?theme(?:\w|\W)+)/, replacerFn);
  }

  ngOnInit(): void {
    this._onNavigationEnd();
  }

  goToPreviousPage(): void {
    this._handleNavigation('back');
  }

  goToNextPage(): void {
    this._handleNavigation('forward');
  }

  // TODO: Might want to remove this later
  // refreshPage(): void {
  //   this._document.location.reload();
  // }

  private _handleNavigation(direction: 'back' | 'forward'): void {
    const steps = direction === 'back' ? -1 : 1;
    let newUrlHistoryIndex = this._currentUrlIndexInHistory;
    newUrlHistoryIndex += steps;
    // navigate only if new index exists
    if (newUrlHistoryIndex >= 0 && newUrlHistoryIndex <= this.history.length - 1) {
      this._isIframeNavigation = true;
      this._currentUrlIndexInHistory = newUrlHistoryIndex;
      this._navigateToUrlInHistory();
    }
    this._toggleNavigationButtons();
  }

  /**
   * Uses an url history index to fetch url in history and navigate
   *
   * We pass skipLocationChange to prevent triggering queryParams change being
   * listened to in recipient-portal.container.ts >> _loadThemeFile, causing a css rebuild
   *
   * @private
   */
  private _navigateToUrlInHistory(): void {
    this._router
      .navigateByUrl(this.history[this._currentUrlIndexInHistory], { skipLocationChange: true })
      .then();
    this._emitUrlToParent(this.history[this._currentUrlIndexInHistory]);
  }

  private _toggleNavigationButtons(): void {
    this.isPreviousDisabled = false;
    this.isNextDisabled = false;

    if (this._currentUrlIndexInHistory === 0) {
      this.isPreviousDisabled = true;
    }
    if (this._currentUrlIndexInHistory === this.history.length - 1) {
      this.isNextDisabled = true;
    }
    this._cdr.markForCheck();
  }

  /**
   * Listens to navigation events and pushes non-iframe navigations to history
   * @private
   */
  private _onNavigationEnd(): void {
    this._router.events
      .pipe(
        untilDestroyed(this),
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        if (!this._isIframeNavigation) {
          const url = AccredibleIframeNavigationComponent._getFormattedHistoryUrl(event.url);
          this.history.push(url);
          this._currentUrlIndexInHistory = this.history.length - 1;
          this._toggleNavigationButtons();
          this._emitUrlToParent(url);
        }
        this._isIframeNavigation = false;
      });
  }

  private _emitUrlToParent(url: string): void {
    window.parent.postMessage({ [this.preview]: `${window.self.location.origin}${url}` }, '*');
  }
}
