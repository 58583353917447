import { sha256 } from 'js-sha256';
import { getDateNowInSeconds } from './date.helper';

/**
 * @author Fred
 *
 * This is done for inter service authentication following the solution in this document
 * https://accredible.atlassian.net/wiki/spaces/PD/pages/3674734675/Backend+Frontend+Inter-Services+Authentication.
 *
 * In the FE we implemented this solution globally in the api.service.ts, meaning that ALL our API requests will
 * send both the X-Signature and X-Timestamp. The BE will just ignore them if they are not needed.
 *
 * If this code lived in the api.service.ts file it would be easy to reverse engineer it
 * just by searching for X-Timestamp in the uglified code.
 * So this file was created with this method in order to better obfuscate the code when
 * it gets uglified by the default config of webpack in Angular.
 * This method also has to be called twice. One time in the api.service.ts as needed and
 * in another place (the end of this file) as a dummy call. Otherwise, webpack would see that this code actually
 * was not needed to be separate, and it would move this code into the api.service.ts.
 *
 * The getDateNowInSeconds() method in date.helper.ts was created for the exact same reason as this one.
 * This way it gets even harder for someone to understand that the payload has a Timestamp on it.
 *
 * This could be just a temporary solution for inter service communication which was implemented to prevent scraping on our endpoints.
 */

const API_SIGNATURE_SECRET = 'chtM@CyP_.7iF.kuQXBv';

export const getXSignature = (method: string, endpoint: string): string => {
  const endpointWithoutQueryParams = endpoint.split('?')[0];

  // payload example: GET /v1/credential-net/organizations/1234 HTTP/1.1 1700487344
  const payload = `${method} ${endpointWithoutQueryParams} HTTP/1.1 ${getDateNowInSeconds()}`;

  return sha256.hmac(API_SIGNATURE_SECRET, payload);
};

// Dummy call
getXSignature('M', 'E');
