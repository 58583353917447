import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { AccredibleToastService } from '@accredible-frontend-v2/toast';
import { Injectable } from '@angular/core';
import { RecaptchaComponent, ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export enum RecaptchaRequest {
  V3 = 'recaptcha_v3',
  V2 = 'recaptcha',
}

//TODO(Manuel) Add more environments and move recaptchaV2TestingSecretKey to all environments files
const testing_environments = ['test'];
const recaptchaV2TestingSecretKey = '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe';

@Injectable({ providedIn: 'root' })
export class AccredibleRecaptchaService {
  readonly userInteracted$ = new BehaviorSubject(false);
  readonly v3Recaptcha$ = new BehaviorSubject(true);

  tokenV2 = '';
  recaptchaComponent: RecaptchaComponent;

  constructor(
    private readonly _recaptchaV3Service: ReCaptchaV3Service,
    private readonly _language: AccredibleLanguageService,
    private readonly _toast: AccredibleToastService,
  ) {}

  submit(action: string, submitCallback: (token: string, isV3: boolean) => void): void {
    this.userInteracted$.next(true);
    if (this.v3Recaptcha$.value) {
      this._executeAction(action).subscribe((token) =>
        submitCallback(token, this.v3Recaptcha$.value),
      );
    } else {
      submitCallback(this.tokenV2, this.v3Recaptcha$.value);
    }
  }

  onActionV2(data: { token: string; recaptchaComponent: RecaptchaComponent }): void {
    if (testing_environments.includes(environment.name)) {
      this.tokenV2 = recaptchaV2TestingSecretKey;
    } else {
      this.tokenV2 = data.token;
    }

    this.recaptchaComponent = data.recaptchaComponent;
  }

  onFailureV3(): void {
    this.v3Recaptcha$.next(false);
  }

  reset(): void {
    this.tokenV2 = null;
    this.userInteracted$.next(false);
  }

  private _executeAction(action: string): Observable<string> {
    return this._recaptchaV3Service.execute(action).pipe(
      catchError((error) => {
        this._toast.error(
          this._language.translate('recaptcha.v3_error', {
            error: error.data,
          }),
        );
        return throwError(error);
      }),
    );
  }
}
