export enum AccredibleKey {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  SPACE = 'Space',
  TAB = 'Tab',
  BACKSPACE = 'Backspace',
  ARROW_UP = 'ArrowUp',
  IE_ARROW_UP = 'Up',
  ARROW_DOWN = 'ArrowDown',
  IE_ARROW_DOWN = 'Down',
  ARROW_LEFT = 'ArrowLeft',
  IE_ARROW_LEFT = 'Left',
  ARROW_RIGHT = 'ArrowRight',
  IE_ARROW_RIGHT = 'Right',
}
