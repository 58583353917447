// 2013 Is when Accredible was founded
export const START_OF_ALL_TIME = new Date(2013, 0);

export const getFullDate = (date: Date): string | Date => {
  if (date && typeof date.getMonth === 'function') {
    return date.getFullYear() + '-' + getMonth(date.getMonth()) + '-' + getDay(date.getDate());
  }
  return date;
};

/*
 * This method is to modify the input date to be shown in
 * Material Datepicker correctly regardless of what timezone the user in
 */
export const correctDateForCurrentTimeZone = (dateString: string): Date => {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const getStartOfDay = (date: Date = new Date()): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const getEndOfDay = (date: Date = new Date()): Date => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

export const getDateOfXDaysAgoCountingToday = (xDays: number): Date => {
  const date = getStartOfDay();
  const xDaysAgo = date.getDate() - xDays;
  // We do xDaysAgo + 1 because we want to count today as a full day
  date.setDate(xDaysAgo + 1);
  return date;
};

/**
 * @see getXSignature
 * to understand why this method was created
 */
export const getDateNowInSeconds = (): string => {
  return Math.round(Date.now() / 1000).toString();
};

const getMonth = (month: number): string => {
  return month < 9 ? '0' + (month + 1) : (month + 1).toString();
};

const getDay = (day: number): string => {
  return day < 10 ? '0' + day : day.toString();
};
