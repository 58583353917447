import Bugsnag from '@bugsnag/js';
import { accredibleCustomThemesMetadata } from './themes';

export class AccredibleBaseThemeHelper {
  static setThemeFavicon(theme: string): void {
    document
      .getElementById('themeFavicon')
      .setAttribute('href', `/assets/themes/${theme}/images/favicon.ico`);
  }

  static loadThemeStyles(themeName: string): Promise<string> {
    return new Promise<string>((resolve) => {
      const themeStylesLink = document.getElementById('themeStyles');
      themeStylesLink.onload = () => {
        resolve(themeName);
      };
      themeStylesLink.onerror = () => {
        // If the @import fonts throws an error the code ends up here, and we can't reject because the app would not load.
        // This problem was seen on theme credentials.advance-he.ac.uk because it was using a non-existing google font Sans-Serif.
        Bugsnag.notify(
          `Failed to load styles probably because of invalid google font. Theme: ${themeName}`,
        );
        resolve(themeName);
      };

      // If this theme is a duplicate, reference the domain of the original theme in order to get the theme styles
      const themeDomain = accredibleCustomThemesMetadata[themeName]?.duplicateOf || themeName;
      themeStylesLink.setAttribute('href', 'assets/themes/' + themeDomain + '/styles/theme.css');
    });
  }
}
