import {
  AccredibleCredential,
  AccredibleCustomCredential,
  AccredibleEvidenceItem,
  AccredibleEvidenceItemInsights,
  AccredibleOrganizationSettings,
  AccredibleUser,
  PathwayEnrolment,
} from '@accredible-frontend-v2/models';
import { createAction, props } from '@ngrx/store';
import {
  CuEvidenceItem,
  LocalEvidenceItem,
} from '../../../containers/credential/models/evidence.model';
import { PhysicalAwardBESuccessResponse } from '../../../containers/credential/models/physical-awards.model';
import { RecommendedGroup } from '../../../containers/credential/models/recommendations.model';
import { Profile } from '../../../containers/profile/models/profile.model';
import { NameChangeRequestResponse } from './credentials.service';

export const loadOrganizationSettings = createAction('[Credentials] Load Organization Settings');
export const loadOrganizationSettingsSuccess = createAction(
  '[Credentials] Load Organization Settings Success',
  props<{ organizationSettings: AccredibleOrganizationSettings }>(),
);
export const loadOrganizationSettingsFailure = createAction(
  '[Credentials] Load Organization Settings Failure',
  props<{ error: any }>(),
);

// Profiles ----------------------------------------
export const loadPublicProfile = createAction(
  '[Credentials] Load Public Profile',
  props<{ username: string; pageSize?: number }>(),
);
export const loadPublicProfileSuccess = createAction(
  '[Credentials] Load Public Profile Success',
  props<{ publicProfile: Profile; pageSize?: number }>(),
);
export const loadPublicProfileFailure = createAction(
  '[Credentials] Load Public Profile Failure',
  props<{ error: any }>(),
);

export const loadPrivateProfile = createAction(
  '[Credentials] Load Private Profile',
  props<{ userId: number; username: string; pageSize?: number }>(),
);
export const loadPrivateProfileSuccess = createAction(
  '[Credentials] Load Private Profile Success',
  props<{ privateProfile: Profile; pageSize?: number }>(),
);
export const loadPrivateProfileFailure = createAction(
  '[Credentials] Load Private Profile Failure',
  props<{ error: any }>(),
);

export const paginateCredentials = createAction(
  '[Credentials] Paginate Credentials',
  props<{ profileType: 'public' | 'private'; pageSize: number }>(),
);
// ------------------------------------------------

// Credential -------------------------------------
export const loadCredential = createAction(
  '[Credentials] Load Credential',
  props<{ idOrUuid: number | string; privateKey?: string }>(),
);
export const loadCredentialSuccess = createAction(
  '[Credentials] Load Credential Success',
  props<{ credential: AccredibleCredential }>(),
);
export const loadCredentialFailure = createAction(
  '[Credentials] Load Credential Failure',
  props<{ error: any }>(),
);

export const loadCredentialUser = createAction(
  '[Credentials] Load Credential User',
  props<{ username: string }>(),
);
export const loadCredentialUserSuccess = createAction(
  '[Credentials] Load Credential User Success',
  props<{ credentialUser: AccredibleUser }>(),
);
export const loadCredentialUserFailure = createAction(
  '[Credentials] Load Credential User Failure',
  props<{ error: any }>(),
);

export const updateCredentialPrivacy = createAction(
  '[Credentials] Update Credential Privacy',
  props<{ credentialId: number; credentialUrl: string; isPrivate: boolean }>(),
);
export const updateCredentialPrivacySuccess = createAction(
  '[Credentials] Update Credential Privacy Success',
  props<{ credentialId: number; credentialUrl: string; isPrivate: boolean; privateKey: string }>(),
);
export const updateCredentialPrivacyFailure = createAction(
  '[Credentials] Update Credential Privacy Failure',
  props<{ error: any }>(),
);

export const requestRecipientNameChange = createAction(
  '[Credentials] Request Recipient Name Change',
  props<{ credentialId: number; updatedName: string }>(),
);
export const requestRecipientNameChangeSuccess = createAction(
  '[Credentials] Request Recipient Name Change Success',
  props<{ nameChangeResponse: NameChangeRequestResponse; statusCode: number }>(),
);
export const requestRecipientNameChangeFailure = createAction(
  '[Credentials] Request Recipient Name Change Failure',
  props<{ error: any }>(),
);
// ------------------------------------------------

// Custom Credential ------------------------------
export const loadCustomCredential = createAction(
  '[Credentials] Load Custom Credential',
  props<{ uuid: string; username: string }>(),
);
export const loadCustomCredentialSuccess = createAction(
  '[Credentials] Load Custom Credential Success',
  props<{ customCredential: AccredibleCustomCredential; username: string }>(),
);
export const loadCustomCredentialFailure = createAction(
  '[Credentials] Load Custom Credential Failure',
  props<{ error: any }>(),
);

export const addCustomCredential = createAction(
  '[Credentials] Add Custom Credential',
  props<{ customCredential: AccredibleCustomCredential }>(),
);
export const addCustomCredentialSuccess = createAction(
  '[Credentials] Add Custom Credential Success',
  props<{ customCredential: AccredibleCustomCredential }>(),
);
export const addCustomCredentialFailure = createAction(
  '[Credentials] Add Custom Credential Failure',
  props<{ error: any }>(),
);

export const editCustomCredential = createAction(
  '[Credentials] Edit Custom Credential',
  props<{ customCredential: AccredibleCustomCredential }>(),
);
export const editCustomCredentialSuccess = createAction(
  '[Credentials] Edit Custom Credential Success',
  props<{ customCredential: AccredibleCustomCredential }>(),
);
export const editCustomCredentialFailure = createAction(
  '[Credentials] Edit Custom Credential Failure',
  props<{ error: any }>(),
);

export const deleteCustomCredential = createAction(
  '[Credentials] Delete Custom Credential',
  props<{ id: number }>(),
);
export const deleteCustomCredentialSuccess = createAction(
  '[Credentials] Delete Custom Credential Success',
  props<{ id: number }>(),
);
export const deleteCustomCredentialFailure = createAction(
  '[Credentials] Delete Custom Credential Failure',
  props<{ error: any }>(),
);
// ------------------------------------------------

// Evidences --------------------------------------
export const loadEvidenceInsights = createAction(
  '[Credentials] Load Evidence Insights',
  props<{ id: number }>(),
);
export const loadEvidenceInsightsSuccess = createAction(
  '[Credentials] Load Evidence Insights Success',
  props<{ evidenceInsights: AccredibleEvidenceItemInsights }>(),
);
export const loadEvidenceInsightsFailure = createAction(
  '[Credentials] Load Evidence Insights Failure',
  props<{ error: any }>(),
);

export const addEvidenceItem = createAction(
  '[Credentials] Add Evidence Item',
  props<{ evidenceItem: CuEvidenceItem }>(),
);
export const addEvidenceItemSuccess = createAction(
  '[Credentials] Add Evidence Item Success',
  props<{ evidenceItem: AccredibleEvidenceItem }>(),
);
export const addEvidenceItemFailure = createAction(
  '[Credentials] Add Evidence Item Failure',
  props<{ error: any }>(),
);

export const editEvidenceItem = createAction(
  '[Credentials] Edit Evidence Item',
  props<{ evidenceItem: CuEvidenceItem }>(),
);
export const editEvidenceItemSuccess = createAction(
  '[Credentials] Edit Evidence Item Success',
  props<{ evidenceItem: AccredibleEvidenceItem }>(),
);
export const editEvidenceItemFailure = createAction(
  '[Credentials] Edit Evidence Item Failure',
  props<{ error: any }>(),
);

export const reorderEvidenceItems = createAction(
  '[Credentials] Reorder Evidence Items',
  props<{
    credentialId: number;
    orderedEvidenceItems: LocalEvidenceItem[];
    initialOrderedItems: LocalEvidenceItem[];
  }>(),
);
export const reorderEvidenceItemsSuccess = createAction(
  '[Credentials] Reorder Evidence Items Success',
);
export const reorderEvidenceItemsFailure = createAction(
  '[Credentials] Reorder Evidence Items Failure',
  props<{ error: any; initialOrderedItems: LocalEvidenceItem[] }>(),
);

export const deleteEvidenceItem = createAction(
  '[Credentials] Delete Evidence Item',
  props<{ credentialId: number; evidenceItemId: number }>(),
);
export const deleteEvidenceItemSuccess = createAction(
  '[Credentials] Delete Evidence Item Success',
  props<{ evidenceItemId: number }>(),
);
export const deleteEvidenceItemFailure = createAction(
  '[Credentials] Delete Evidence Item Failure',
  props<{ error: any }>(),
);
// ------------------------------------------------

// Evidences --------------------------------------
export const loadRecommendations = createAction(
  '[Credentials] Load Recommendations',
  props<{ groupId: number }>(),
);
export const loadRecommendationsSuccess = createAction(
  '[Credentials] Load Recommendations Success',
  props<{ course_recommendations: RecommendedGroup[] }>(),
);
export const loadRecommendationsFailure = createAction(
  '[Credentials] Load Recommendations Failure',
  props<{ error: any }>(),
);
// ------------------------------------------------

// TODO (JOAO) The current file is difficult to maintain, and it would be beneficial to divide it into smaller, more manageable files
// Pathways
export const loadPathwayEnrolment = createAction(
  '[Credentials] Load Pathway Enrolment',
  props<{ username: string; credentialId: string }>(),
);
export const loadPathwayEnrolmentSuccess = createAction(
  '[Credentials] Load Pathway Enrolment Success',
  props<{ pathwayEnrolments: PathwayEnrolment[] }>(),
);
export const loadPathwayEnrolmentFailure = createAction(
  '[Credentials] Load Pathway Enrolment Failure',
  props<{ error: any }>(),
);

// Physical Awards
export const updatePurchaseIntent = createAction(
  '[Credentials] Update Purchase Intent',
  props<{ params: PhysicalAwardBESuccessResponse; uniqueIdentifier: string }>(),
);
export const updatePurchaseIntentSuccess = createAction(
  '[Credentials] Update Purchase Intent Success',
  props<{ success: boolean }>(),
);
export const updatePurchaseIntentFailure = createAction(
  '[Credentials] Update Purchase Intent Failure',
  props<{ error: any }>(),
);
// ------------------------------------------------

// RESET
export const resetValue = createAction('[Credentials] Reset Value', props<{ name: string }>());
export const resetState = createAction('[Credentials] Reset');
