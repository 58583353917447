import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

/**
 * A reference to the toast itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
export class AccredibleToastRef {
  private readonly _afterClosedSubject$ = new Subject<boolean>();

  constructor(private readonly _overlayRef: OverlayRef) {}

  /**
   * Dismisses the overlay
   */
  dismiss(dismissedWithAction = false): void {
    this._overlayRef.dispose();
    this._afterClosedSubject$.next(dismissedWithAction);
    this._afterClosedSubject$.complete();
  }

  /**
   * An Observable that notifies when the overlay has been dismissed
   */
  afterDismiss(): Observable<boolean> {
    return this._afterClosedSubject$.asObservable();
  }
}
