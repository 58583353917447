import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { AccredibleIframeNavigationComponent } from './iframe-navigation.component';

const MATERIAL_MODULES = [MatButtonModule, MatTooltipModule];

@NgModule({
  imports: [RouterModule, ...MATERIAL_MODULES],
  exports: [AccredibleIframeNavigationComponent],
  declarations: [AccredibleIframeNavigationComponent],
})
export class AccredibleIframeNavigationComponentModule {}
