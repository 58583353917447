import { AccredibleResponsiveMenuItem } from '@accredible-frontend-v2/components/responsive-menu';
import { environment } from '@accredible-frontend-v2/envs';
import {
  CustomHeaderLink,
  ThemeHelperInterface,
  ThemeJSONFile,
} from '@accredible-frontend-v2/models';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

const MERGE = 'merge';
const STYLESHEET = 'stylesheet';
const HREF = 'href';

enum HtmlElementKey {
  THEME_OVERRIDES_STYLES = 'themeOverridesStyles',
  THEME_STYLES = 'themeStyles',
  HEAD = 'head',
  LINK = 'link',
}

enum BrowserStorageKey {
  THEME_DOMAIN = 'themeDomain',
  THEME_HEADER_CONFIG = 'themeHeaderConfig',
  CURRENT_THEME_SOURCE = 'currentThemeSource',
  CURRENT_THEME_ID = 'currentThemeId',
}

enum BrowserStorageKeyValue {
  PREVIEWS = 'previews',
  ASSETS = 'assets',
}

enum UrlParam {
  THEME = 'theme',
  KEY = 'key',
}

@UntilDestroy()
@Injectable()
export class ThemeGeneratorService {
  private readonly THEMING_S3_BASE_URL = environment.themingS3BaseUrl;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _http: HttpClient,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _browserStorage: AccredibleBrowserStorageService,
  ) {}

  setTheme(ThemeHelper: ThemeHelperInterface): Promise<string> {
    // Remove any previous themeDomain and themeHeaderConfig
    this._browserStorage.delete(BrowserStorageKey.THEME_DOMAIN);
    this._browserStorage.delete(BrowserStorageKey.THEME_HEADER_CONFIG);

    return new Promise((resolve) => {
      let currentThemeId;

      // Check if app is running inside TG
      if (environment.theming) {
        const urlParams = new URLSearchParams(this._document.location.search);
        const theme = urlParams.get(UrlParam.THEME);
        const key = urlParams.get(UrlParam.KEY);
        const browserStorageKeyValue = key
          ? BrowserStorageKeyValue.PREVIEWS
          : theme
          ? BrowserStorageKeyValue.ASSETS
          : '';
        const browserStorageValue = key || theme;

        if (browserStorageValue) {
          this._browserStorage.set(BrowserStorageKey.CURRENT_THEME_SOURCE, browserStorageKeyValue);
          this._browserStorage.set(BrowserStorageKey.CURRENT_THEME_ID, browserStorageValue);

          if (!WindowHelper.isInIframe()) {
            this._setupPersistentUrlKey(browserStorageValue);
          }
        }

        currentThemeId = this._browserStorage.get(BrowserStorageKey.CURRENT_THEME_ID);
        const currentThemeSource = this._browserStorage.get(BrowserStorageKey.CURRENT_THEME_SOURCE);
        if (currentThemeId) {
          this.loadThemeConfigAndStyles(currentThemeId, currentThemeSource).then((res) =>
            resolve(res),
          );
        }
      }

      if (!environment.theming || !currentThemeId) {
        // Default logic
        const themeName = ThemeHelper.getTheme();
        ThemeHelper.setThemeFavicon(themeName);
        ThemeHelper.loadThemeStyles(themeName).then((res) => resolve(res));
      }
    });
  }

  loadThemeConfigAndStyles(currentThemeId: string, currentThemeSource: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // TODO: Once we know the final structure of what we want we should combine the logo and links into one browserStorage set e.g. themeHeaderData
      // If we have a header logo override we should store it so the default header can access it
      // if (res.config.headerFooter.header.logoFileName) {
      //   this._browserStorage.set('themeHeaderLogoSrc', res.config.headerFooter.header.logoFileName);
      // } else {
      //   this._browserStorage.delete('themeHeaderLogoSrc');
      // }
      // TODO: This will be used when the Theme generator can add links to the default header
      // if (res.config.headerFooter.header.extraLinks) {
      //   this._browserStorage.set('themeHeaderLinks', '');
      // } else {
      //   this._browserStorage.delete('themeHeaderLinks');
      // }

      this.loadThemeStyles(currentThemeId, currentThemeSource);

      this.loadThemeConfig(currentThemeId).subscribe((res: ThemeJSONFile) => {
        this._browserStorage.set(BrowserStorageKey.THEME_DOMAIN, res.config.themeDomain);

        // Builder the ThemeHeaderConfig object
        // FIXME: We need to map the logo values here when they are complete
        const themeHeaderConfig: { customHeaderLinks: CustomHeaderLink[] } = {
          customHeaderLinks: res.config.headerFooter.customHeaderLinks,
        };

        this._browserStorage.set(
          BrowserStorageKey.THEME_HEADER_CONFIG,
          JSON.stringify(themeHeaderConfig),
        );
        this.loadThemeOverridesStyles(res.config.themeDomain).then(
          () => resolve(res.name),
          () => reject(),
        );
      });
    });
  }

  loadThemeConfig(themeId: string): Observable<ThemeJSONFile> {
    return this._http.get<ThemeJSONFile>(
      `${this.THEMING_S3_BASE_URL}/configs/${themeId}.json?v=${new Date().getTime()}`,
    );
  }

  loadThemeStyles(themeId: string, source: string): void {
    const themeStyles = this._document.getElementById(HtmlElementKey.THEME_STYLES);
    const version = new Date().getTime();
    themeStyles.setAttribute(
      HREF,
      `${this.THEMING_S3_BASE_URL}/${source}/${themeId}.css?v=${version}`,
    );
  }

  loadThemeOverridesStyles(themeDomain: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const version = new Date().getTime();
      const stylesUrl = `${this.THEMING_S3_BASE_URL}/overrides/${themeDomain}.css?v=${version}`;

      // Check if this theme has override styles (if request fails it doesn't)
      this._http.get(stylesUrl, { responseType: 'text' }).subscribe({
        next: () => {
          // This if statement is needed because when the theme changes, we are listening to query param
          // changes, and since we already have the themeOverridesStyles element on the HTML,
          // we don't need to re-create it, only change its href
          if (this._document.getElementById(HtmlElementKey.THEME_OVERRIDES_STYLES)) {
            const themeOverridesStyles = this._document.getElementById(
              HtmlElementKey.THEME_OVERRIDES_STYLES,
            );
            themeOverridesStyles.setAttribute(HREF, stylesUrl);
          } else {
            const head = document.head || document.getElementsByTagName(HtmlElementKey.HEAD)[0];
            const themeOverridesStylesLink = document.createElement(HtmlElementKey.LINK);
            themeOverridesStylesLink.rel = STYLESHEET;
            themeOverridesStylesLink.id = HtmlElementKey.THEME_OVERRIDES_STYLES;
            themeOverridesStylesLink.href = stylesUrl;
            head.appendChild(themeOverridesStylesLink);

            themeOverridesStylesLink.onload = () => {
              resolve();
            };
            themeOverridesStylesLink.onerror = () => {
              reject();
            };
          }
        },
        error: () => {
          resolve();
        },
      });
    });
  }

  getThemeHeaderLinks(themeDomain: string): Observable<{ customHeaderLinks: CustomHeaderLink[] }> {
    return this._http.get<{ customHeaderLinks: CustomHeaderLink[] }>(
      `/assets/themes/${themeDomain}/header-links.json`,
    );
  }

  createCustomHeaderLinks(
    customLinkArray: { label: string; link: string }[],
  ): AccredibleResponsiveMenuItem[] {
    if (customLinkArray && customLinkArray.length > 0) {
      return customLinkArray.map((el, index) => {
        return {
          id: `custom-link-${index}`,
          label: el.label,
          externalLink: el.link,
        };
      });
    } else {
      return [];
    }
  }

  /**
   * Append theme key (id) to url for every route as a param, if it is not already present.
   * This is to ensure that the previewed theme isn't replaced by a different theme that is running in another tab.
   */
  private _setupPersistentUrlKey(themeId: string): void {
    this._router.events
      .pipe(
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        if (!this._route.snapshot.queryParams.key) {
          // This changes the key query param without refreshing route
          this._router
            .navigate([], {
              relativeTo: this._route,
              queryParams: { key: themeId },
              replaceUrl: true,
              queryParamsHandling: MERGE,
            })
            .then();
        }
      });
  }
}
