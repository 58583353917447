import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleExternalLinkDirective } from './external-link.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [AccredibleExternalLinkDirective],
  exports: [AccredibleExternalLinkDirective],
})
export class AccredibleExternalLinkDirectiveModule {}
