import { AccredibleLanguage } from '@accredible-frontend-v2/models';
import { Direction } from '@angular/cdk/bidi';
import { HashMap } from '@jsverse/transloco/lib/types';
import { Observable, of } from 'rxjs';

export class AccredibleLanguageServiceMock {
  languageCode$ = of('en');

  setLanguage(languageCode: string, themeName: string): void {}

  getSelectedLanguage(): AccredibleLanguage {
    return <AccredibleLanguage>{};
  }

  getDirection(): Direction {
    return 'rtl';
  }

  translate(key: string, params?: HashMap): string {
    return '';
  }

  selectTranslate(key: string, params?: HashMap): Observable<string> {
    return of('');
  }
}
