import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { DefaultToastComponentModule } from './components/default-toast/default-toast.component.module';
import { AccredibleToastService } from './service/toast.service';
import { AccredibleToastConfig } from './toast.config';

const MATERIAL_MODULES = [MatSnackBarModule, MatButtonModule];

@NgModule({
  imports: [DefaultToastComponentModule, ...MATERIAL_MODULES],
  exports: [],
  declarations: [],
  providers: [AccredibleToastService],
})
export class AccredibleToastModule {
  constructor(@Optional() @SkipSelf() parentModule?: AccredibleToastModule) {
    if (parentModule) {
      throw new Error('AccredibleToastModule is already loaded. Import it in the core module only');
    }
  }

  static forRoot(config: AccredibleToastConfig): ModuleWithProviders<AccredibleToastModule> {
    return {
      ngModule: AccredibleToastModule,
      providers: [{ provide: AccredibleToastConfig, useValue: config }],
    };
  }
}
