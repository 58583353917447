export interface FontSelection {
  target: string;
  family: string;
  fallbackFont: string;
  variant: string;
  size: number;
  lineHeight?: number;
  spacing: number;
}

export interface TypographyConfiguration {
  display4: FontSelection;
  display3: FontSelection;
  display2: FontSelection;
  display1: FontSelection;
  headline: FontSelection;
  title: FontSelection;
  subheading2: FontSelection;
  subheading1: FontSelection;
  body2: FontSelection;
  body1: FontSelection;
  button: FontSelection;
  caption: FontSelection;
  input: FontSelection;
}
