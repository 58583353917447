import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MyLearningFacadeService } from '../../../shared/services/my-learning/my-learning-facade.service';

@Injectable({
  providedIn: 'root',
})
export class MyLearningPathwayEnrolmentsGuard implements CanActivate {
  constructor(
    private readonly _router: Router,
    private readonly _userApi: AccredibleUserApiService,
    private readonly _myLearningFacade: MyLearningFacadeService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const currentUser = this._userApi.getUser();

    return this._myLearningFacade
      .getMyLearningPathwayEnrolments(currentUser.username)
      .pipe(
        map(
          ({ pathway_enrolments }) =>
            !!pathway_enrolments.length || this._router.createUrlTree(['404']),
        ),
      );
  }
}
