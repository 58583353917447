import { AccredibleButtonLoadingComponentModule } from '@accredible-frontend-v2/components/button-loading';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { AccredibleDialogTemplateComponent } from './dialog-template.component';

const MATERIAL_MODULES = [MatDialogModule, MatButtonModule, MatIconModule];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AccredibleButtonLoadingComponentModule,
    TranslocoModule,
    ...MATERIAL_MODULES,
  ],
  exports: [AccredibleDialogTemplateComponent],
  declarations: [AccredibleDialogTemplateComponent],
  providers: [],
})
export class AccredibleDialogTemplateComponentModule {}
