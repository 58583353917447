import { NgModule } from '@angular/core';
import { AccredibleIconComponent } from './icon.component';

@NgModule({
  imports: [],
  exports: [AccredibleIconComponent],
  declarations: [AccredibleIconComponent],
  providers: [],
})
export class AccredibleIconComponentModule {}
