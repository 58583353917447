import { environment } from '@accredible-frontend-v2/envs';
import Bugsnag, { Client } from '@bugsnag/js';

export const startBugsnag = (appType: string): Client => {
  return Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    appType,
    appVersion: environment.version,
    // TODO (Fred): We disabled bugsnag automatic reports because it was consuming all quota and therefore blocking BE from using it.
    //  We can enable them again if we start properly using it.
    autoDetectErrors: false,
    autoTrackSessions: false,
  });
};
