<ng-container *ngIf="icon && iconPosition === 'left'"
  [ngTemplateOutlet]="iconTemplate">
</ng-container>
<span *ngIf="!hideText"
  class="button-text">
  <ng-content></ng-content>
</span>
<ng-container *ngIf="icon && iconPosition === 'right'"
  [ngTemplateOutlet]="iconTemplate">
</ng-container>


<ng-template #iconTemplate>
  <accredible-icon
    [style.font-size]="iconSize"
    [icon]="icon"
    [@rotateAnimation]="rotateIcon ? 'rotated': 'initial'">
  </accredible-icon>
</ng-template>


<accredible-icon *ngIf="isLoading"
  [icon]="'loading'"
  class="animation-spin">
</accredible-icon>
