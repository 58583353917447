<ng-container *ngIf="componentData$ | async as componentData">
  <ng-container *transloco="let t; read: 'recaptcha'">
    <!-- Source: https://developers.google.com/recaptcha/docs/faq -->
    <p *ngIf="componentData.v3Recaptcha"
      class="mat-caption"
      [innerHTML]="t('terms')">
    </p>

    <ng-container *ngIf="!componentData.v3Recaptcha && loaderReady">
      <re-captcha #recaptchaRef
        (resolved)="onActionV2($event)">
      </re-captcha>

      <div *ngIf="!tokenV2 && !isLoading && componentData.userInteracted"
        class="recaptcha-error"
        aria-live="polite">
        <mat-icon class="trailing-margin-narrow"
          aria-hidden="false"
          [attr.aria-label]="t('aria_error')">warning
        </mat-icon>
        <span>{{ t('required') }}</span>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
