export const EarningCriteriaTypeValues = {
  certificate: 'ec-certificate',
  course: 'ec-course',
  degree: 'ec-degree',
  exam: 'ec-exam',
  experience: 'ec-experience',
  knowledge: 'ec-knowledge',
  license: 'ec-license',
  participation: 'ec-participation',
  reading: 'ec-reading',
  skill: 'ec-skill',
  specialization: 'ec-specialization',
  other: 'ec-other',
};

export type EarningCriteriaName = keyof typeof EarningCriteriaTypeValues;
export type EarningCriteriaIcon = typeof EarningCriteriaTypeValues[EarningCriteriaName];

export interface EarningCriteria {
  id: string;
  kind: keyof typeof EarningCriteriaTypeValues;
  text: string;
  url?: string;
  required: boolean;
  position: number;
  _destroy?: boolean;
}
