import { AccredibleBaseInputInterface } from '@accredible-frontend-v2/forms/utils';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'accredible-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: [`./checkbox-input.component.scss`],
  // ViewEncapsulation.None is used to avoid using ::ng-deep when we have to make exceptions on the styles for this component.
  encapsulation: ViewEncapsulation.None,
  // We are not using ChangeDetectionStrategy.OnPush because the material input component
  // does not detect changes on the formControlRef (invalid, dirty or touched)
  // and errors do not get triggered with formGroup.markAllAsTouched() alone
})
export class AccredibleCheckboxInputComponent implements AccredibleBaseInputInterface {
  @Input()
  formControlRef: UntypedFormControl;
  @Input()
  required = false;
  @Input()
  color: ThemePalette = 'accent';
  @Input()
  size: 'normal' | 'large' = 'normal';
  @Input()
  tabIndex: number;

  @Output()
  change = new EventEmitter<boolean>();
}
