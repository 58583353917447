/**
 * This base component has a very similar implementation of AccredibleBaseFormComponent,
 * but since we want to extend the AccredibleBaseDialogComponent and can't extend two classes in Typescript,
 * we couldn't reuse the code from the AccredibleBaseFormComponent.
 * In order to make sure that this component is always well implemented we created and implemented here the BaseFormInterface.
 * Everytime the implementation changes in AccredibleBaseFormComponent, the interface BaseFormInterface should also change,
 * in order for an error to be thrown here so that we don't forget to make the same changes in this file.
 */

import { BaseFormHelper, BaseFormInterface } from '@accredible-frontend-v2/forms/utils';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { AccredibleKey } from '@accredible-frontend-v2/utils/key-enum';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccredibleDialogService } from '../service/dialog.service';
import { AccredibleBaseDialogComponent } from './base-dialog.component';

@UntilDestroy()
@Injectable()
export class AccredibleBaseFormDialogComponent<C, T>
  extends AccredibleBaseDialogComponent<C>
  implements BaseFormInterface<T>, OnDestroy
{
  formGroup: UntypedFormGroup;
  formGroupBehaviorSubject$: BehaviorSubject<T>;
  formGroupObservable$: Observable<T>;
  isDirty$: Observable<boolean>;

  private _isDirtySetup = false;

  constructor(
    public dialogRef: MatDialogRef<C>,
    protected _el: ElementRef<HTMLElement>,
    protected _focusMonitor?: FocusMonitor,
    protected _trigger?: ElementRef<HTMLElement>,
    protected _dialog?: AccredibleDialogService,
    protected _language?: AccredibleLanguageService,
  ) {
    super(dialogRef, _focusMonitor, _trigger);
  }

  ngOnDestroy(): void {
    if (this._isDirtySetup) {
      this._resetIsDirty();
    }
  }

  close(actioned = false): void {
    if (this._isDirtySetup) {
      this._safeDialogClose();
    } else {
      this.dialogRef.close(actioned);
    }
  }

  getFormGroup(formGroup: UntypedFormGroup, key1: string, key2?: string): UntypedFormGroup {
    return BaseFormHelper.getFormGroup(formGroup, key1, key2);
  }

  getFormArray(formGroup: UntypedFormGroup, key1: string, key2?: string): UntypedFormArray {
    return BaseFormHelper.getFormArray(formGroup, key1, key2);
  }

  getFormArrayControl(formArrayControl: AbstractControl): UntypedFormControl {
    return BaseFormHelper.getFormArrayControl(formArrayControl);
  }

  getFormControl(formGroup: UntypedFormGroup, key1: string, key2?: string): UntypedFormControl {
    return BaseFormHelper.getFormControl(formGroup, key1, key2);
  }

  /**
   * Call this method on ngOnInit if you need your dialog component to have dirty validation setup
   *
   * Dirty Validation will prevent the user from closing the form,
   * (when trying to close the dialog or trying to close the website browser tab)
   * without saving changes
   */
  setupFormDirtyValidation(): void {
    this._isDirtySetup = true;
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this._safeDialogClose());
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === AccredibleKey.ESCAPE) {
        this._safeDialogClose();
      }
    });

    this.formGroupBehaviorSubject$ = new BehaviorSubject(this.formGroup.getRawValue());
    this.formGroupObservable$ = this.formGroupBehaviorSubject$.asObservable();
    this.isDirty$ = dirtyCheck(this.formGroup, this.formGroupObservable$);

    this.formGroupObservable$
      .pipe(untilDestroyed(this))
      .subscribe((state) => this.formGroup.patchValue(state, { emitEvent: false }));
  }

  _isFormValid(): boolean {
    if (this.formGroup.valid) {
      return true;
    }

    // Trigger form validations
    this.formGroup.markAllAsTouched();
    // Focus the first invalid input
    (<HTMLElement>this._el.nativeElement.querySelector('input.ng-invalid'))?.focus();

    return false;
  }

  /**
   * This resets the isDirty value on the @ngneat/dirty-check-forms library,
   * avoiding an alert of showing up that prevents the url changing/refreshing,
   * when the form was actually submitted successfully
   */
  _resetIsDirty(): void {
    this.formGroupBehaviorSubject$.next(this.formGroup.getRawValue());
  }

  private _safeDialogClose(): void {
    this.isDirty$.pipe(take(1)).subscribe(async (isDirty) => {
      if (isDirty) {
        const canClose = await this._dialog
          .confirm({
            title: this._language.translate('confirm-dialog.discard.title'),
            text: this._language.translate('confirm-dialog.discard.dialog_message'),
            btnActionText: this._language.translate('confirm-dialog.close'),
          })
          .afterClosed()
          .toPromise();

        if (canClose) {
          this.dialogRef.close();
        }
      } else {
        this.dialogRef.close();
      }
    });
  }
}
