export interface AccredibleEvidenceItem {
  id: number;
  description: string;
  preview_image_url: string;
  link_url: string;
  type: AccredibleEvidenceItemType;
  // Miriada - MiriadaTranscriptStringObject and string added only for Miriada
  string_object:
    | AccredibleTranscriptStringObject[]
    | MiriadaTranscriptStringObject
    | AccredibleGradeStringObject
    | string
    | string[][];
  supplemental: boolean;
  position: number;
  file_type: string;
  hidden: boolean;
  insight?: AccredibleEvidenceInsight[];
}

export enum AccredibleEvidenceItemType {
  YOUTUBE_VIDEO = 'video',
  GRADE = 'grade',
  TRANSCRIPT = 'transcript',
  IMAGE = 'image',
  PDF = 'pdf',
  URL = 'url',
  URL_NO_SCREENSHOT = 'url_no_screenshot',
  GENERIC_FILE = 'file',
  STATIC_TEXT = 'static_text',
  // Miriada only evidence types
  MIRIADA_VIDEO = 'video_embed',
  MIRIADA_OUTLINE = 'miriada_outline',
  MIRIADA_PARTICIPATION = 'miriada_participation',
  MIRIADA_TEACHER_OUTLINE = 'miriada_teacher_outline',
  MIRIADA_CUSTOM_EVIDENCE_TYPE = 'miriada_custom_reference',
}

export interface AccredibleTranscriptStringObject {
  category: string;
  percent: number;
}

// Miriada TranscriptStringObject
export interface MiriadaTranscriptStringObject {
  headers: string[];
  content: string[][];
  title: string;
}

export interface AccredibleGradeStringObject {
  grade: number;
  grade_breakup?: AccredibleTranscriptStringObject[];
}

export interface AccredibleEvidenceItemInsights {
  id: number;
  insight: AccredibleEvidenceInsight[];
}

export enum AccredibleEvidenceInsightType {
  AVERAGE_SCORE = 'average score',
  COUNT = 'count',
  DATE = 'date',
  DESCRIPTION = 'description',
  DURATION = 'duration',
  END_DATE = 'end_date',
  ITEMS = 'items',
  PERCENTILE = 'percentile',
  SKILLS = 'skills',
  START_DATE = 'start_date',
  TIME_TO_END = 'time to read',
  WORD_COUNT = 'word count',
  TOP_SCORE = 'top score',
}

export interface AccredibleEvidenceInsight {
  type: string | AccredibleEvidenceInsightType;
  text: string;
}
