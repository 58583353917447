export interface GeneralConfiguration {
  borderRadius: number;
  subheadingCase: 'uppercase' | 'lowercase' | 'capitalize';
  boxShadow: string;
  buttonBorderRadius: number;
}

export interface TextBackgroundConfiguration {
  text?: string;
  background?: string;
  backgroundImage?: string;
}

export interface PrimaryButtonConfiguration {
  text: string;
  hover: string;
  backgroundHover: string;
}

export interface ButtonConfiguration {
  text: string;
  background: string;
  border: string;
  borderRadius?: string;
  hover?: string;
}

export interface MenuConfiguration extends TextBackgroundConfiguration {
  hover: string;
  backgroundHover: string;
  underline: boolean;
}

export interface ResponsiveMenuConfiguration {
  text: string;
  hoverText: string;
}

export interface CredentialBannerConfiguration {
  selectedColor: string;
  background: string;
  backgroundImage: string;
}

export interface BaseTileConfiguration {
  color: string;
  textColor: string;
  boxShadow: string;
}

export interface TileConfiguration extends BaseTileConfiguration {
  imageBgColor: string;
  borderRadius: number;
  hoverBoxShadow: string;
  border: string;
}

export interface TileButtonsConfiguration {
  color: string;
  hoverColor: string;
  textColor: string;
  hoverTextColor: string;
}

export interface HeaderTileConfiguration extends BaseTileConfiguration {}

// TODO: remove after property migration
export interface OthersConfiguration {
  borderRadius: number;
  boxShadow: string;
  subheadingCase: 'uppercase' | 'lowercase' | 'capitalize';
}

export interface ComponentsConfiguration {
  general: GeneralConfiguration;
  body: TextBackgroundConfiguration;
  primaryButtons: PrimaryButtonConfiguration;
  buttons: ButtonConfiguration;
  menu: MenuConfiguration;
  responsiveMenu: ResponsiveMenuConfiguration;
  tooltips: TextBackgroundConfiguration;
  chips: TextBackgroundConfiguration;
  headerTile: HeaderTileConfiguration;
  tile: TileConfiguration;
  tileButtons: TileButtonsConfiguration;
  credentialBanner: CredentialBannerConfiguration;
  others: OthersConfiguration;
}
