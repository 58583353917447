import { Client } from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

export class AccredibleGlobalBugsnagErrorHandler extends BugsnagErrorHandler {
  constructor(client: Client) {
    super(client);
  }

  handleError(error: any): void {
    super.handleError(error);

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}
