import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MyLearningApiService } from '../../services/my-learning/my-learning.service';
import { MyLearningPathwayEnrolmentsEffects } from './my-learning-pathway-enrolments.effects';
import * as MyLearningPathwayEnrolmentsStore from './my-learning-pathway-enrolments.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(
      MyLearningPathwayEnrolmentsStore.myLearningPathwayEnrolmentsFeatureKey,
      MyLearningPathwayEnrolmentsStore.reducer,
    ),
    EffectsModule.forFeature([MyLearningPathwayEnrolmentsEffects]),
  ],
  exports: [],
  declarations: [],
  providers: [MyLearningApiService],
})
export class MyLearningPathwayEnrolmentsStoreModule {}
