export enum AccountsRedirectionKey {
  APP = 'app',
  ORIGIN = 'origin',
  HOST = 'host',
  LANGUAGE = 'language',
  SESSION_TOKEN_QUERY_PARAM = 'st',
  SESSION_TOKEN_ONE_TIME = 'token',
  SESSION_TOKEN_COOKIE = 'session_token',
  SET_PASSWORD = 'setPassword',
  RECIPIENT_PORTAL_URL = 'recipientPortalUrl',
}

export type APPS = 'accounts' | 'recipient-portal' | 'spotlight' | 'coursefinder';
