import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleLanguageServiceModule } from '@accredible-frontend-v2/services/language';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslocoModule } from '@jsverse/transloco';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaLoaderService,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { AccredibleRecaptchaComponent } from './component/recaptcha.component';
import { RecaptchaDynamicLanguageLoaderService } from './service/dynamic-language-loader.service';

const MAT_MODULES = [MatIconModule, MatInputModule];

@NgModule({
  imports: [
    CommonModule,
    AccredibleLanguageServiceModule,
    RecaptchaModule,
    TranslocoModule,
    ...MAT_MODULES,
  ],
  exports: [AccredibleRecaptchaComponent],
  declarations: [AccredibleRecaptchaComponent],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaV2SiteKey,
      } as RecaptchaSettings,
    },
    {
      provide: RecaptchaLoaderService,
      useClass: RecaptchaDynamicLanguageLoaderService,
    },
  ],
})
export class AccredibleRecaptchaModule {}
