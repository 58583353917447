export interface DashboardCorporation {
  certificate_left: number;
  certificates_allowance: number;
  id: number;
  logo: string;
  name: string;
  subscription_created_at: string;
  url: string;
  verified: boolean;
}
