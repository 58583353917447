import {
  AccredibleCredential,
  AccredibleCustomCredential,
  AccredibleEvidenceItemInsights,
  AccredibleOrganizationSettings,
  AccredibleUser,
  PathwayEnrolment,
} from '@accredible-frontend-v2/models';
import { AccredibleUrlHelper } from '@accredible-frontend-v2/utils/url-helper';
import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { RecommendedGroup } from '../../../containers/credential/models/recommendations.model';
import { Profile, ProfileCounts } from '../../../containers/profile/models/profile.model';
import { WalletCredential } from '../../../containers/profile/models/wallet-credential.model';
import * as CredentialsActions from './credentials.actions';
import { getCounts, mapCustomWalletCredential, setupCredentials } from './credentials.helper';
import { NameChangeRequestStatus } from './credentials.service';

export const credentialsFeatureKey = 'credential';

export interface CredentialsState {
  organizationSettings: AccredibleOrganizationSettings;
  profileCounts: ProfileCounts;

  /**
   * Profile credentials caching logic:
   *
   * {profileType}CredentialsCache - saves credentials not being shown to the user, they are going to be paginated.
   * {profileType}Credentials - saves credentials that are being shown to the user.
   * If we send a pageSize it means we are on the wallet or transcript, hence we only want to have one cache working (public or private), the one not being used is in its initial state.
   * If we don't send a pageSize it means we are on site map, hence we need to have both public and private caches working if a logged in user is seeing another user's wallet site map.
   *
   * NOTE: Credentials inside privateProfile or publicProfile should never be used for display.
   */

  publicCredentialsCache: WalletCredential[];
  publicCredentials: WalletCredential[];
  publicProfile: Profile;

  privateCredentialsCache: WalletCredential[];
  privateCredentials: WalletCredential[];
  privateProfile: Profile;

  credential: AccredibleCredential;
  customCredential: AccredibleCustomCredential;
  credentialUser: AccredibleUser;
  evidenceInsights: AccredibleEvidenceItemInsights[];
  courseRecommendations: RecommendedGroup[];
  pathwayEnrolments: PathwayEnrolment[];
  successfullyPurchasedPhysicalAward: boolean;

  action: CredentialsStateAction;
  payload: any;
  error: any;
}

export enum CredentialsStateAction {
  NO_ACTION,

  ORGANIZATION_SETTINGS_LOADED,

  PUBLIC_PROFILE_LOADED,
  PRIVATE_PROFILE_LOADED,

  CREDENTIAL_LOADED,
  CREDENTIAL_PRIVACY_UPDATED,
  CREDENTIAL_PRIVACY_UPDATE_FAILED,
  RECIPIENT_NAME_CHANGE_REQUESTED,
  RECIPIENT_NAME_CHANGE_APPROVED, // Used for when a name change request is simple (1-2 characters) and therefore auto approved

  CUSTOM_CREDENTIAL_ADDED,
  CUSTOM_CREDENTIAL_SAVED,

  PATHWAY_ENROLMENT_LOADED,
  PURCHASE_INTENT_UPDATE_SUCCESSFULLY,

  EVIDENCE_ADDED,
  EVIDENCE_SAVED,

  RECOMMENDATION_LOADED,

  HAS_ERROR,
}

export const initialStateHandling: Partial<CredentialsState> = {
  action: CredentialsStateAction.NO_ACTION,
  payload: null,
  error: null,
};

export const initialState = <CredentialsState>{
  organizationSettings: {},
  profileCounts: {},

  publicCredentialsCache: [],
  publicCredentials: [{}, {}, {}],
  publicProfile: {},

  privateCredentialsCache: [],
  privateCredentials: [{}, {}, {}],
  privateProfile: {},

  credential: <AccredibleCredential>{
    recipient: {},
    issuer: {},
    group: {},
    learning_outcomes: [null, null, null],
    evidence_items: [],
  },
  customCredential: null,
  credentialUser: {},
  evidenceInsights: [{}, {}, {}, {}],
  successfullyPurchasedPhysicalAward: false,
  ...initialStateHandling,
};

const credentialsReducer = createReducer(
  initialState,

  on(CredentialsActions.loadOrganizationSettings, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.loadOrganizationSettingsSuccess, (state, { organizationSettings }) => ({
    ...state,
    organizationSettings:
      !organizationSettings || !_.isEmpty(organizationSettings)
        ? <AccredibleOrganizationSettings>{
            ...organizationSettings,
            logo: organizationSettings?.logo || state.organizationSettings.logo,
          }
        : initialState.organizationSettings,
    action: CredentialsStateAction.ORGANIZATION_SETTINGS_LOADED,
  })),
  on(CredentialsActions.loadOrganizationSettingsFailure, (state) => ({
    ...state,
    action: CredentialsStateAction.HAS_ERROR,
  })),

  on(CredentialsActions.paginateCredentials, (state, { profileType, pageSize }) => ({
    ...state,
    [`${profileType}Credentials`]: [
      ...state[`${profileType}Credentials`],
      ...state[`${profileType}CredentialsCache`].slice(0, pageSize),
    ],
    [`${profileType}CredentialsCache`]: state[`${profileType}CredentialsCache`].slice(pageSize),
    ...initialStateHandling,
  })),

  // Public profile
  on(CredentialsActions.loadPublicProfile, (state, { username, pageSize }) => {
    return profileInitialState(state, 'public', username, pageSize);
  }),
  on(CredentialsActions.loadPublicProfileSuccess, (state, { publicProfile, pageSize }) => {
    const profileCounts = getCounts(publicProfile, state.organizationSettings);
    const credentials = setupCredentials(
      publicProfile.credentials,
      publicProfile.custom_credentials,
      state.organizationSettings,
      publicProfile.username,
    );

    return {
      ...state,
      publicCredentialsCache: pageSize ? credentials.slice(pageSize) : [],
      publicCredentials: pageSize ? credentials.slice(0, pageSize) : credentials,
      publicProfile,
      profileCounts,
      action: CredentialsStateAction.PUBLIC_PROFILE_LOADED,
    };
  }),
  on(CredentialsActions.loadPublicProfileFailure, (state) => ({
    ...state,
    action: CredentialsStateAction.HAS_ERROR,
  })),

  // Private profile
  on(CredentialsActions.loadPrivateProfile, (state, { username, pageSize }) => {
    return profileInitialState(state, 'private', username, pageSize);
  }),
  on(CredentialsActions.loadPrivateProfileSuccess, (state, { privateProfile, pageSize }) => {
    const profileCounts = getCounts(privateProfile, state.organizationSettings);
    const credentials = setupCredentials(
      privateProfile.credentials,
      privateProfile.custom_credentials,
      state.organizationSettings,
      privateProfile.username,
    );

    return {
      ...state,
      privateCredentialsCache: pageSize ? credentials.slice(pageSize) : [],
      privateCredentials: pageSize ? credentials.slice(0, pageSize) : credentials,
      privateProfile,
      profileCounts,
      action: CredentialsStateAction.PRIVATE_PROFILE_LOADED,
    };
  }),
  on(CredentialsActions.loadPrivateProfileFailure, (state) => ({
    ...state,
    action: CredentialsStateAction.HAS_ERROR,
  })),

  // Credential
  on(CredentialsActions.loadCredential, (state, { idOrUuid }) => {
    // Use cached credential if idOrUuid is the same
    const useCachedCredential =
      state.credential.id === idOrUuid || state.credential.uuid === idOrUuid;
    return {
      ...state,
      credential: useCachedCredential ? state.credential : initialState.credential,
      customCredential: useCachedCredential
        ? state.customCredential
        : initialState.customCredential,
      evidenceInsights: useCachedCredential
        ? state.evidenceInsights
        : initialState.evidenceInsights,

      ...initialStateHandling,
      action: useCachedCredential
        ? CredentialsStateAction.CREDENTIAL_LOADED
        : CredentialsStateAction.NO_ACTION,
    };
  }),
  on(CredentialsActions.loadCredentialSuccess, (state, { credential }) => ({
    ...state,
    credential,
    // Remove empty objects (ghosts)
    evidenceInsights: state.evidenceInsights.filter((value) => Object.keys(value).length !== 0),
    action: CredentialsStateAction.CREDENTIAL_LOADED,
  })),
  on(CredentialsActions.loadCredentialFailure, (state) => ({
    ...state,
    action: CredentialsStateAction.HAS_ERROR,
  })),

  on(CredentialsActions.loadCredentialUser, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.loadCredentialUserSuccess, (state, { credentialUser }) => ({
    ...state,
    credentialUser: {
      ...credentialUser,
      // If all properties inside social_media are null or empty save social_media as null
      social_media: Object.values(credentialUser.social_media).every(
        (medium) => medium === null || medium === '',
      )
        ? null
        : credentialUser.social_media,
    },
  })),

  on(CredentialsActions.updateCredentialPrivacy, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(
    CredentialsActions.updateCredentialPrivacySuccess,
    (state, { credentialId, credentialUrl, isPrivate, privateKey }) => {
      let finalUrl: URL;

      if (isPrivate) {
        finalUrl = AccredibleUrlHelper.createUrl(credentialUrl, 'Private credential url');
      } else {
        finalUrl = AccredibleUrlHelper.createUrl(credentialUrl, 'Public credential url');
        finalUrl?.searchParams.delete('key');
      }

      return {
        ...state,
        privateCredentials: state.privateCredentials.map((credential) => {
          if (credential.id === credentialId) {
            return {
              ...credential,
              isPrivate,
              privateKey,
              privateUrl: finalUrl?.href,
            };
          }
          return credential;
        }),
        credential:
          state.credential.id === credentialId
            ? {
                ...state.credential,
                private: isPrivate,
                private_key: privateKey,
                url: finalUrl.href,
              }
            : state.credential,
        action: CredentialsStateAction.CREDENTIAL_PRIVACY_UPDATED,
      };
    },
  ),
  on(CredentialsActions.updateCredentialPrivacyFailure, (state) => ({
    ...state,
    action: CredentialsStateAction.CREDENTIAL_PRIVACY_UPDATE_FAILED,
  })),

  on(CredentialsActions.requestRecipientNameChange, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(
    CredentialsActions.requestRecipientNameChangeSuccess,
    (state, { nameChangeResponse, statusCode }) => ({
      ...state,
      credential:
        // If the name change request is auto approved we update the name in the credential recipient object to the new name
        nameChangeResponse.status === NameChangeRequestStatus.AUTO_APPROVE
          ? {
              ...state.credential,
              recipient: { ...state.credential.recipient, name: nameChangeResponse.new_name },
            }
          : state.credential,
      action:
        nameChangeResponse.status === NameChangeRequestStatus.PENDING
          ? CredentialsStateAction.RECIPIENT_NAME_CHANGE_REQUESTED
          : CredentialsStateAction.RECIPIENT_NAME_CHANGE_APPROVED,
      payload: statusCode,
    }),
  ),
  on(CredentialsActions.requestRecipientNameChangeFailure, (state, { error }) => ({
    ...state,
    // 422 means there is a pending request
    action:
      error.status === 422
        ? CredentialsStateAction.RECIPIENT_NAME_CHANGE_REQUESTED
        : CredentialsStateAction.HAS_ERROR,
    payload: error.status === 422 ? error.status : null,
    error: error,
  })),

  // Custom Credential
  on(CredentialsActions.loadCustomCredential, (state) => ({
    ...state,
    credential: initialState.credential,
    customCredential: initialState.customCredential,
    ...initialStateHandling,
  })),
  on(CredentialsActions.loadCustomCredentialSuccess, (state, { customCredential, username }) => ({
    ...state,
    customCredential: {
      ...customCredential,
      username,
    },
    action: CredentialsStateAction.CREDENTIAL_LOADED,
  })),
  on(CredentialsActions.addCustomCredential, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.addCustomCredentialSuccess, (state, { customCredential }) => ({
    ...state,
    privateCredentials: [
      mapCustomWalletCredential(customCredential, state.privateProfile.username),
      ...state.privateCredentials,
    ],
    action: CredentialsStateAction.CUSTOM_CREDENTIAL_ADDED,
  })),

  on(CredentialsActions.editCustomCredential, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.editCustomCredentialSuccess, (state, { customCredential }) => ({
    ...state,
    privateCredentials: state.privateCredentials.map((credential) => {
      if (credential.uuid === customCredential.uuid) {
        return mapCustomWalletCredential(customCredential, state.privateProfile.username);
      }
      return credential;
    }),
    action: CredentialsStateAction.CUSTOM_CREDENTIAL_SAVED,
  })),

  on(CredentialsActions.deleteCustomCredential, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.deleteCustomCredentialSuccess, (state, { id }) => ({
    ...state,
    privateCredentials: state.privateCredentials.filter((credential) => credential.id !== id),
  })),

  // Evidences
  on(CredentialsActions.loadEvidenceInsights, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.loadEvidenceInsightsSuccess, (state, { evidenceInsights }) => ({
    ...state,
    evidenceInsights:
      state.evidenceInsights.findIndex((insights) => insights.id === evidenceInsights.id) > -1
        ? state.evidenceInsights.map((insights) => {
            if (insights.id === evidenceInsights.id) {
              return evidenceInsights;
            }
            return insights;
          })
        : [...state.evidenceInsights, evidenceInsights],
  })),
  on(CredentialsActions.addEvidenceItem, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.addEvidenceItemSuccess, (state, { evidenceItem }) => ({
    ...state,
    credential: {
      ...state.credential,
      evidence_items: [...state.credential.evidence_items, evidenceItem],
    },
    action: CredentialsStateAction.EVIDENCE_ADDED,
  })),

  on(CredentialsActions.editEvidenceItem, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.editEvidenceItemSuccess, (state, { evidenceItem }) => ({
    ...state,
    credential: {
      ...state.credential,
      evidence_items: state.credential.evidence_items.map((item) => {
        if (item.id === evidenceItem.id) {
          return evidenceItem;
        }
        return item;
      }),
    },
    action: CredentialsStateAction.EVIDENCE_SAVED,
  })),

  on(CredentialsActions.reorderEvidenceItems, (state, { orderedEvidenceItems }) => {
    // Reorder evidenceInsights array to match the evidenceItems array
    // before the response arrives in order to provide the user with a better UX
    // If the request fails we reinstate the original order
    const orderedEvidenceInsights: AccredibleEvidenceItemInsights[] = [];
    orderedEvidenceItems.forEach((evidenceItem) => {
      const insight = state.evidenceInsights.find(
        (evidenceInsight) => evidenceInsight.id === evidenceItem.id,
      );
      orderedEvidenceInsights.push(insight);
    });

    return {
      ...state,
      ...initialStateHandling,

      credential: {
        ...state.credential,
        evidence_items: orderedEvidenceItems,
      },
      evidenceInsights: orderedEvidenceInsights,
    };
  }),
  on(CredentialsActions.reorderEvidenceItemsSuccess, (state) => state),
  on(CredentialsActions.reorderEvidenceItemsFailure, (state, { initialOrderedItems }) => {
    // Reorder evidenceInsights array to match the evidenceItems array
    const initialOrderedEvidenceInsights: AccredibleEvidenceItemInsights[] = [];
    initialOrderedItems.forEach((evidenceItem) => {
      const insight = state.evidenceInsights.find(
        (evidenceInsight) => evidenceInsight.id === evidenceItem.id,
      );
      initialOrderedEvidenceInsights.push(insight);
    });

    return {
      ...state,

      // On failure go back to the initial order
      credential: {
        ...state.credential,
        evidence_items: initialOrderedItems,
      },
      evidenceInsights: initialOrderedEvidenceInsights,

      action: CredentialsStateAction.HAS_ERROR,
    };
  }),

  on(CredentialsActions.deleteEvidenceItem, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.deleteEvidenceItemSuccess, (state, { evidenceItemId }) => ({
    ...state,
    credential: {
      ...state.credential,
      evidence_items: state.credential.evidence_items.filter((item) => item.id !== evidenceItemId),
    },
    evidenceInsights: state.evidenceInsights.filter((insight) => insight.id !== evidenceItemId),
  })),

  // Recommendation
  on(CredentialsActions.loadRecommendations, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CredentialsActions.loadRecommendationsSuccess, (state, { course_recommendations }) => ({
    ...state,
    courseRecommendations: [...course_recommendations],
    action: CredentialsStateAction.RECOMMENDATION_LOADED,
  })),
  on(CredentialsActions.loadPathwayEnrolmentSuccess, (state, { pathwayEnrolments }) => ({
    ...state,
    pathwayEnrolments,
    action: CredentialsStateAction.PATHWAY_ENROLMENT_LOADED,
  })),
  // Physical Awards
  on(CredentialsActions.updatePurchaseIntentSuccess, (state, response) => ({
    ...state,
    successfullyPurchasedPhysicalAward: response.success,
    action: CredentialsStateAction.PURCHASE_INTENT_UPDATE_SUCCESSFULLY,
  })),
  on(
    CredentialsActions.loadRecommendationsFailure,
    CredentialsActions.deleteEvidenceItemFailure,
    CredentialsActions.editEvidenceItemFailure,
    CredentialsActions.addEvidenceItemFailure,
    CredentialsActions.loadEvidenceInsightsFailure,
    CredentialsActions.deleteCustomCredentialFailure,
    CredentialsActions.deleteCustomCredentialFailure,
    CredentialsActions.editCustomCredentialFailure,
    CredentialsActions.addCustomCredentialFailure,
    CredentialsActions.loadCustomCredentialFailure,
    CredentialsActions.loadCredentialUserFailure,
    CredentialsActions.loadPathwayEnrolmentFailure,
    (state) => ({
      ...state,
      action: CredentialsStateAction.HAS_ERROR,
    }),
  ),

  // RESET
  on(CredentialsActions.resetValue, (state, { name }) => ({
    ...state,
    ...initialStateHandling,
    [name]: initialState[name],
  })),
  on(CredentialsActions.resetState, () => initialState),
);
/**
 * Profile credentials caching logic:
 *
 * {profileType}CredentialsCache - saves credentials not being shown to the user, they are going to be paginated.
 * {profileType}Credentials - saves credentials that are being shown to the user.
 * If we send a pageSize it means we are on the wallet or transcript, hence we only want to have one cache working (public or private), the one not being used is in its initial state.
 * If we don't send a pageSize it means we are on site map, hence we need to have both public and private caches working if a logged in user is seeing another user's wallet site map.
 *
 * NOTE: Credentials inside privateProfile or publicProfile should never be used for display.
 */
const profileInitialState = (
  state: CredentialsState,
  profileType: 'public' | 'private',
  username: string,
  pageSize?: number,
): CredentialsState => {
  const useCachedProfile = state[`${profileType}Profile`].username === username;
  const allCredentials = useCachedProfile
    ? [...state[`${profileType}Credentials`], ...state[`${profileType}CredentialsCache`]]
    : [];

  if (pageSize) {
    const otherProfileType = profileType === 'public' ? 'private' : 'public';
    return {
      ...state,

      [`${profileType}CredentialsCache`]: useCachedProfile
        ? allCredentials.slice(pageSize)
        : initialState[`${profileType}CredentialsCache`],

      [`${profileType}Credentials`]: useCachedProfile
        ? allCredentials.slice(0, pageSize)
        : initialState[`${profileType}Credentials`],

      [`${profileType}Profile`]: useCachedProfile
        ? state[`${profileType}Profile`]
        : initialState[`${profileType}Profile`],

      [`${otherProfileType}CredentialsCache`]: initialState[`${otherProfileType}CredentialsCache`],
      [`${otherProfileType}Credentials`]: initialState[`${otherProfileType}Credentials`],
      [`${otherProfileType}Profile`]: initialState[`${otherProfileType}Profile`],

      ...initialStateHandling,
      action: useCachedProfile
        ? profileType === 'private'
          ? CredentialsStateAction.PRIVATE_PROFILE_LOADED
          : CredentialsStateAction.PUBLIC_PROFILE_LOADED
        : CredentialsStateAction.NO_ACTION,
    };
  }

  return {
    ...state,

    [`${profileType}CredentialsCache`]: [],

    [`${profileType}Credentials`]: useCachedProfile
      ? allCredentials
      : initialState[`${profileType}Credentials`],

    [`${profileType}Profile`]: useCachedProfile
      ? state[`${profileType}Profile`]
      : initialState[`${profileType}Profile`],

    ...initialStateHandling,
  };
};

export function reducer(state: CredentialsState, action: Action): CredentialsState {
  return credentialsReducer(state, action);
}
