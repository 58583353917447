import { AccredibleTrackingApiService, TrackLink } from '@accredible-frontend-v2/services/tracking';
import { AccredibleUrlHelper } from '@accredible-frontend-v2/utils/url-helper';
import { DOCUMENT } from '@angular/common';
import { Directive, HostBinding, HostListener, Inject, Input } from '@angular/core';

@Directive({
  // Track all a tags that are not router links
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a:not([routerLink])',
})
export class AccredibleExternalLinkDirective {
  @HostBinding('rel')
  @Input()
  rel = 'noopener';

  private _internalDomains = [
    'accredible.com',
    'badge.design',
    'certificates.design',
    'coursefinder.io',
    'credential.net',
  ];

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _trackingApi: AccredibleTrackingApiService,
  ) {}

  // TODO: Rename this method and check if substring is needed
  private static _getTrimmedHtml(html: string): string {
    return html
      .replace(/^\s+|\s+$/g, '') // ..trimmed
      .substring(0, 256); // ...truncated
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    const element = <HTMLElement>event.currentTarget;
    const to = element.getAttribute('href');
    const toUrl = AccredibleUrlHelper.createUrl(to, 'External link url');

    if (toUrl) {
      // Do nothing if its an internal URL
      for (let i = 0; i < this._internalDomains.length; i++) {
        if (toUrl.origin.includes(this._internalDomains[i])) {
          return;
        }
      }

      const trackLink: TrackLink = {
        from: this._document.location.href,
        to,
        html: AccredibleExternalLinkDirective._getTrimmedHtml(element.outerHTML),
        aad: toUrl.searchParams.get('aad'),
      };
      this._trackingApi.trackExternalLink(trackLink);
    }
  }
}
