import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AccredibleBaseDialogComponent } from '../utils/base-dialog.component';

@Component({
  selector: 'accredible-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: [`./dialog-template.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleDialogTemplateComponent implements OnInit {
  @Input()
  confirmDialogTitle: string;
  @Input()
  btnActionText = this._language.translate('dialog-template.ok');
  @Input()
  btnActionColor: 'primary' | 'warn' = 'primary';
  @Input()
  btnCancelText: string;
  @Input()
  formGroup: UntypedFormGroup;
  @Input()
  isSavingForm = false;
  @Input()
  showActions = true;
  @Input()
  disableClose = false;
  @Input()
  disableBtnAction = false;
  @Input()
  alignActions: 'start' | 'center' | 'end' = 'end';

  @Output()
  btnActionClick = new EventEmitter();
  @Output()
  btnCancelClick = new EventEmitter();

  dialogTitle: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dialogTitle: string },
    public readonly dialogRef: MatDialogRef<AccredibleBaseDialogComponent<unknown>>,
    private readonly _language: AccredibleLanguageService,
  ) {}

  ngOnInit(): void {
    this.dialogTitle = this.data.dialogTitle;
  }

  onCancelBtnClick(): void {
    if (this.formGroup) {
      this.btnCancelClick.emit();
    } else {
      this.dialogRef.close();
    }
  }
}
