import { AccredibleGroup } from './group.model';
import { SkillCategory } from './skill-category.model';

export type VerticalDirection = 'up' | 'down';

export enum PathwayPublishStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export enum PathwayInformationType {
  CERTIFICATE = 'certificate',
  COURSE = 'course',
  DEGREE = 'degree',
  EXAM = 'exam',
  EXPERIENCE = 'experience',
  KNOWLEDGE = 'knowledge',
  LICENSE = 'license',
  PARTICIPATION = 'participation',
  READING = 'reading',
  SKILL = 'skill',
  SPECIALIZATION = 'specialization',
  OTHER = 'other',
}

export interface PathwayInformation {
  name: string;
  description?: string;
  kind: PathwayInformationType;
  url?: string;
  meta_data?: Record<string, string>;
}

export enum PathwayType {
  DIPLOMA = 'diploma',
  CERTIFICATE_PROGRAM = 'certificate_program',
  JOB_TITLE = 'job_title',
}

export const PATHWAY_TYPE_IMAGES = {
  [PathwayType.DIPLOMA]: '/assets/images/pathway-types/diploma.svg',
  [PathwayType.CERTIFICATE_PROGRAM]: '/assets/images/pathway-types/certificate_program.svg',
  [PathwayType.JOB_TITLE]: '/assets/images/pathway-types/job_title.svg',
};

export enum PathwayPlan {
  CONNECT = 'connect', // TODO(Salim): Remove after updating all places
  CONNECT_3 = 'connect_3',
  CONNECT_6 = 'connect_6',
  CONNECT_9 = 'connect_9',
  CONNECT_UNLIMITED = 'connect_limited',
  GROWTH = 'growth',
}

export interface PathwayListItem {
  id: string;
  name: string;
  pathway_type: PathwayType;
  pathway_type_image: string;
  updated_at: string;
  publish_status: PathwayPublishStatus;
  has_unpublished_changes: boolean;
  nodes_count: number;
  node_groups_count: number;
  group: AccredibleGroup;
  pathway_node_item_groups_count: number;
  pathway_version_uuid: string;
  published_pathway_version_uuid: string;
}

export interface PathwayVersionResponse {
  pathway_version: PathwayVersion;
}

export interface PathwayResponse {
  pathway: Pathway;
}

export interface Pathway {
  pathway_versions: PathwayVersion[];
  uuid: string;
}

export interface PathwaySummary {
  pathway_versions: PathwaySummaryVersion[];
  uuid: string;
}

export interface PathwayVersionBase {
  uuid: string;
  publish_status: PathwayPublishStatus;
  name: string;
  description: string | null;
  skill_category_id?: number;
  skill_category?: SkillCategory;
  show_enrolment_stats?: boolean;
  pathway_type: PathwayType;
  enrolment_strategy: PathwayEnrolmentStrategy;
}

export interface PathwayVersion extends PathwayVersionBase {
  pathway_node: PathwayNode;
  total_completion_count?: number;
  pathway_node_item_groups_count?: number;
  pathway_node_groups_count?: number;
}

export interface PathwaySummaryVersion extends PathwayVersionBase {
  created_at: string;
  updated_at: string;
  pathway_type: PathwayType;
  pathway_nodes_count: number;
  pathway_node_groups_count: number;
  pathway_node_item_groups_count: number;
  pathway_information_node_items_count: number;
  total_completion_count?: number;
  group: AccredibleGroup;
  pathway_node: {
    uuid: string;
    name: string;
    description: string;
    group: AccredibleGroup;
  };
}

export interface PathwayNode {
  uuid: string;
  name: string | null;
  group: AccredibleGroup; // This is the milestone group
  parent_pathway_node_uuid: string | null; // This is the id of the node_item's parent node that the node belongs to. It is null for the root node
  parent_pathway_node_item_uuid: string | null; // This is the id of the node_item that the node belongs to. It is null for the root node
  parent_pathway_node_item_kind: PathwayNodeItemKind | null; // This is the kind of the node_item that the node belongs to. It is null for the root node
  optional_items_required_num?: number | null;
  pathway_node_items: PathwayNodeItem[];
  enrolment_completed_count?: number;
  is_tracked?: boolean;
}

export enum PathwayNodeItemKind {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  ATTACHED = 'attached',
  PREREQUISITES = 'prerequisites',
}

export interface PathwayNodeItem {
  kind: PathwayNodeItemKind;
  uuid: string;
  position: number;
  parent_pathway_node_uuid: string; // This is the id of the node that the node_item belongs to
  pathway_node?: PathwayNode;
  group?: AccredibleGroup;
  pathway_information?: PathwayInformation;
}

export interface PathwaysCSVExport {
  status: PathwaysCSVExportStatus;
  url: string;
}

export enum PathwaysCSVExportStatus {
  STARTED = 'started',
  COMPLETED = 'completed',
  ERROR = 'completed_with_error',
}

export enum PathwayEnrolmentStrategy {
  AUTOMATIC = 'automatic',
  CLOSED = 'closed',
}
