import { AccredibleDialogTemplateComponentModule } from '@accredible-frontend-v2/dialog';
import { AccredibleCheckboxInputComponentModule } from '@accredible-frontend-v2/forms/checkbox-input';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslocoModule } from '@jsverse/transloco';
import { EnableMfaPromptDialogComponent } from './enable-mfa-prompt-dialog.component';

const MATERIAL_MODULES = [MatButtonModule];

@NgModule({
  imports: [
    AccredibleDialogTemplateComponentModule,
    TranslocoModule,
    AccredibleCheckboxInputComponentModule,
    ...MATERIAL_MODULES,
  ],
  exports: [],
  declarations: [EnableMfaPromptDialogComponent],
  providers: [],
})
export class EnableMfaPromptDialogComponentModule {}
