export enum AccredibleResponsiveBreakpoint {
  NONE = 0,
  XXS = 360,
  XS = 576,
  SM = 768,
  MD = 992,
  LG = 1260,
  ML = 1440,
  VL = 1600,
  SL = 1920,
}

const orderedBreakpoints = ['XXS', 'XS', 'SM', 'MD', 'LG', 'ML', 'VL', 'SL'];

export class AccredibleResponsiveHelper {
  static isScreenGreaterThan(breakpoint: AccredibleResponsiveBreakpoint): boolean {
    return window.innerWidth >= breakpoint;
  }

  static isScreenLessThan(breakpoint: AccredibleResponsiveBreakpoint): boolean {
    return window.innerWidth < breakpoint;
  }

  static getBreakPoint(): AccredibleResponsiveBreakpoint {
    for (let i = 0; i < orderedBreakpoints.length - 1; i++) {
      if (
        window.innerWidth >=
          AccredibleResponsiveBreakpoint[
            orderedBreakpoints[i] as keyof typeof AccredibleResponsiveBreakpoint
          ] &&
        window.innerWidth <
          AccredibleResponsiveBreakpoint[
            orderedBreakpoints[i + 1] as keyof typeof AccredibleResponsiveBreakpoint
          ]
      ) {
        return AccredibleResponsiveBreakpoint[
          orderedBreakpoints[i] as keyof typeof AccredibleResponsiveBreakpoint
        ];
      }
    }

    return AccredibleResponsiveBreakpoint.NONE;
  }
}
