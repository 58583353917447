import { environment } from '@accredible-frontend-v2/envs';
import {
  AccredibleCredential,
  AccredibleCustomCredential,
  AccredibleOrganizationSettings,
} from '@accredible-frontend-v2/models';
import { sortObjectDescending } from '@accredible-frontend-v2/utils/sort-helper';
import { AccredibleUrlHelper } from '@accredible-frontend-v2/utils/url-helper';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { Profile, ProfileCounts } from '../../../containers/profile/models/profile.model';
import { WalletCredential } from '../../../containers/profile/models/wallet-credential.model';
import { WhitelabelHelper } from '../../helpers/whitelabel.helper';

export const setupCredentials = (
  credentials: AccredibleCredential[],
  customCredentials: AccredibleCustomCredential[],
  organizationSettings: AccredibleOrganizationSettings,
  username: string,
): WalletCredential[] => {
  const filteredCredentials = filterCredentialsByOrganizationSettings(
    credentials,
    organizationSettings,
  );
  let mappedCredentials = mapWalletCredentials(filteredCredentials, customCredentials, username);
  mappedCredentials = sortCredentials(mappedCredentials);
  return mappedCredentials;
};

const mapWalletCredentials = (
  credentials: AccredibleCredential[],
  customCredentials: AccredibleCustomCredential[],
  username: string,
): WalletCredential[] => {
  const isCustomDomain = WhitelabelHelper.isCustomDomain() && !environment.theming;
  const walletCredentials: WalletCredential[] = credentials.map(
    (credential: AccredibleCredential) => {
      let walletCredential: WalletCredential = null;
      if (credential.name) {
        walletCredential = {
          id: credential.id,
          uuid: credential.uuid,
          name: credential.name,
          url: credential.url,
          external: isCustomDomain && !hostMatchesCredentialUrl(credential?.url),

          certificateDesign: credential.group?.certificate_design,
          badgeDesign: credential.group?.badge_design,

          issuerId: credential.issuer?.id,
          issuerName: credential.issuer?.name,
          issuedOn: credential.issued_on,

          website: credential.course_link,
          websiteLabel: AccredibleUrlHelper.createUrl(
            credential.course_link,
            'Profile transcript issuer website',
          )?.hostname,
          grade: credential.grade,

          isPrivate: credential.private,
          privateKey: credential.private_key,
          privateUrl: credential.url,
          isExpired: credential.expired,
          isCustomCredential: false,

          credential,
        };
      }
      return walletCredential;
    },
  );

  const customWalletCredentials: WalletCredential[] = customCredentials.map((customCredential) => {
    return mapCustomWalletCredential(customCredential, username);
  });

  return [...walletCredentials, ...customWalletCredentials];
};

export const mapCustomWalletCredential = (
  customCredential: AccredibleCustomCredential,
  username: string,
): WalletCredential => {
  return <WalletCredential>{
    id: customCredential.id,
    uuid: customCredential.uuid,
    name: customCredential.name,
    username,

    imageUrl: customCredential.url,

    issuerId: null,
    issuerName: customCredential.issuer_name,
    issuedOn: customCredential.issue_date,

    isExpired: customCredential.expiry_date
      ? new Date() > new Date(customCredential.expiry_date)
      : false,
    isPrivate: false,
    isCustomCredential: true,

    customCredential,
  };
};

const filterCredentialsByOrganizationSettings = (
  credentials: AccredibleCredential[],
  organizationSettings: AccredibleOrganizationSettings,
): AccredibleCredential[] => {
  return credentials.filter((credential) =>
    belongsToDepartmentOrOrganization(credential, organizationSettings),
  );
};

export const sortCredentials = (credentials: WalletCredential[]): WalletCredential[] => {
  return credentials.sort((a, b) => sortObjectDescending(a, b, 'issuedOn'));
};

export const getCounts = (
  profile: Profile,
  organizationSettings: AccredibleOrganizationSettings,
): ProfileCounts => {
  const counts: ProfileCounts = {
    allCredentials: profile.credentials.length + profile.custom_credentials.length,
    hiddenCredentials: 0,
    visibleIssuers: 0,
    hiddenIssuers: 0,
  };

  const issuers = {};
  const hiddenIssuers = {};
  profile.credentials.forEach((credential) => {
    if (belongsToDepartmentOrOrganization(credential, organizationSettings)) {
      issuers[credential.issuer.name] = true;
    } else {
      hiddenIssuers[credential.issuer.name] = true;
    }
  });

  profile.custom_credentials.forEach((credential) => {
    if (!!organizationSettings.credential_visibility) {
      hiddenIssuers[credential.issuer_name] = true;
    } else {
      issuers[credential.issuer_name] = true;
    }
  });

  counts.visibleIssuers = Object.keys(issuers).length;
  counts.hiddenIssuers = Object.keys(hiddenIssuers).length;

  counts.hiddenCredentials =
    profile.credentials.filter(
      (credential) => !belongsToDepartmentOrOrganization(credential, organizationSettings),
    ).length +
    (!!organizationSettings.credential_visibility ? profile.custom_credentials.length : 0);

  return counts;
};

const belongsToDepartmentOrOrganization = (
  credential: AccredibleCredential,
  organizationSettings: AccredibleOrganizationSettings,
) => {
  switch (organizationSettings.credential_visibility) {
    case 'department':
      return credential.issuer.id === organizationSettings.department_id;
    case 'organization':
      return organizationSettings.organization_departments.includes(credential.issuer.id);
    default:
      return true;
  }
};

const hostMatchesCredentialUrl = (credentialUrl: string): boolean => {
  if (!credentialUrl || !AccredibleUrlHelper.isValidUrl(credentialUrl)) {
    return false;
  }

  const currentHostName = WindowHelper.window.location.hostname;
  const credentialUrlHostName = AccredibleUrlHelper.createUrl(
    credentialUrl,
    'Credential Url',
  ).hostname;

  return currentHostName === credentialUrlHostName;
};
