import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { AccredibleToastData } from '../model/toast.model';
import { ToastAction } from '../service/toast.service';
import { AccredibleToastRef } from '../toast-ref';
import { TOAST_DATA } from '../toast-tokens';

@Component({
  selector: 'accredible-toast',
  templateUrl: 'toast.component.html',
  styleUrls: [`./toast.component.scss`],
  // ViewEncapsulation.None in order to be able to style the overlay panel without using ::ng-deep
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent {
  @Input()
  status: ToastAction;

  constructor(
    @Inject(TOAST_DATA) public data: AccredibleToastData,
    private readonly _toastRef: AccredibleToastRef,
  ) {}

  dismiss(): void {
    this._toastRef.dismiss(true);
  }
}
