import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AccredibleAuthenticationServiceConfig } from './authentication.config';
import { AccredibleAuthenticationApiService } from './authentication.service';

@NgModule({
  providers: [AccredibleAuthenticationApiService],
})
export class AccredibleAuthenticationServiceModule {
  constructor(@Optional() @SkipSelf() parentModule?: AccredibleAuthenticationServiceModule) {
    if (parentModule) {
      throw new Error(
        'AccredibleAuthenticationServiceModule is already loaded. Import it in the core module only',
      );
    }
  }

  static forRoot(
    config: AccredibleAuthenticationServiceConfig,
  ): ModuleWithProviders<AccredibleAuthenticationServiceModule> {
    return {
      ngModule: AccredibleAuthenticationServiceModule,
      providers: [{ provide: AccredibleAuthenticationServiceConfig, useValue: config }],
    };
  }
}
