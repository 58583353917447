import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RecaptchaComponent, RecaptchaLoaderService } from 'ng-recaptcha';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { RecaptchaDynamicLanguageLoaderService } from '../service/dynamic-language-loader.service';
import { AccredibleRecaptchaService } from '../service/recaptcha.service';

@UntilDestroy()
@Component({
  selector: 'accredible-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: [`./recaptcha.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleRecaptchaComponent implements OnInit {
  @Input()
  isLoading = false;

  @Output()
  actionV2 = new EventEmitter<RecaptchaComponent>();

  @ViewChild('recaptchaRef')
  recaptchaComponent: RecaptchaComponent;

  readonly componentData$ = combineLatest([
    this.recaptcha.userInteracted$,
    this.recaptcha.v3Recaptcha$,
  ]).pipe(
    map(([userInteracted, v3Recaptcha]) => {
      return {
        userInteracted,
        v3Recaptcha,
      };
    }),
  );

  tokenV2 = this.recaptcha.tokenV2;
  loaderReady = false;

  constructor(
    @Inject(RecaptchaLoaderService) private readonly loader: RecaptchaDynamicLanguageLoaderService,
    public readonly recaptcha: AccredibleRecaptchaService,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
  ) {
    recaptcha.reset();
  }

  ngOnInit(): void {
    this.loader.ready.pipe(untilDestroyed(this)).subscribe((v) => {
      setTimeout(
        () =>
          this.zone.run(() => {
            this.loaderReady = !!v;
            this.cdr.markForCheck();
          }),
        0,
      );
    });
  }

  onActionV2(token: string): void {
    this.recaptcha.onActionV2({ token, recaptchaComponent: this.recaptchaComponent });
    this.actionV2.emit(this.recaptchaComponent);
  }
}
