export class WindowHelper {
  static window: any = window;

  static removeQueryParamFromUrl(queryParam: string, urlParams: URLSearchParams): void {
    if (urlParams.has(queryParam)) {
      urlParams.delete(queryParam);
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname +
          window.location.hash +
          (urlParams.toString().length > 0 ? '?' + urlParams.toString() : ''),
      );
    }
  }

  static isInIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static isLocalhost(): boolean {
    const hostname = window.location.hostname;
    // For browserstack testing change hostname === 'localhost' with hostname.includes('localhost')
    return hostname === 'localhost' || hostname === '127.0.0.1';
  }

  static isSandboxSite(): boolean {
    const hostname = window.location.hostname;
    return hostname.includes('sandbox.credential.net');
  }

  /**
   * Method that receives an itemHeight in pixels and number of horizontalItems (used when it's a grid),
   * and returns the number of items that fit on the screen
   *
   * When using infinite scrolling,
   * we should always ask for a little bit more items than what really fits on the screen,
   * but, since in every screen we always have a header and maybe even something else,
   * the number of items that fit on the screen is already a little bit more than what can be displayed
   *
   * @param itemHeight
   * @param horizontalItems
   */
  static numberOfRowsThatFitOnScreen(itemHeight: number, horizontalItems: number = 1): number {
    return Math.ceil(window.innerHeight / itemHeight) * horizontalItems;
  }

  /**
   * Fetches the value of a CSS custom property from the DOM
   * @param value - Property name, e.g: "var(--color-natural-100)" should be sent as "color-natural-100"
   */
  static getCSSPropertyValue(value: string): string {
    return getComputedStyle(document.body).getPropertyValue(`--${value}`);
  }
}
