import { AccredibleUrlHelper } from '@accredible-frontend-v2/utils/url-helper';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OneTimeAuthenticatedGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): UrlTree {
    // Redirect to either url or success_url and forward any query params belonging to this URL
    const url = AccredibleUrlHelper.createUrl(
      route.queryParams.url || route.queryParams.success_url,
      'one-time-authenticated url',
    );

    if (url) {
      const queryParams = {};
      url.searchParams.forEach((value, key) => {
        queryParams[key] = value;
      });

      return this._router.createUrlTree([url.pathname], { queryParams });
    }

    return null;
  }
}
