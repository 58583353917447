import { AccredibleTableColumn } from '@accredible-frontend-v2/components/table';
import { AccredibleEvidenceItem, AccredibleObjectWithId } from '@accredible-frontend-v2/models';

export interface LocalEvidenceItem extends AccredibleEvidenceItem {
  youtubeImageUrl?: string;
  youtubeEmbedUrl?: string;
  evidenceTranscriptData?: AccredibleObjectWithId[];

  // Miriada start
  isMiriada: boolean;
  vimeoEmbedUrl?: string;
  miriadaTranscriptData?: AccredibleObjectWithId[];
  miriadaTableColumns?: AccredibleTableColumn[];
  // Miriada end

  subtitle1: LocalEvidenceItemSubtitle;
  subtitle2: LocalEvidenceItemSubtitle;
}

export interface LocalEvidenceItemSubtitle {
  icon: string;
  text: string;
}

export interface CuEvidenceItem {
  credentialId: number;
  evidenceItem: { id: number; description?: string; url?: string; hidden?: boolean };
}

export enum EvidenceItemInsightType {
  // Video
  DURATION = 'duration',

  // Grade
  PERCENTILE = 'percentile',
  AVERAGE_SCORE = 'average score',
  // Miriada
  FULL_SIZE_DESCRIPTION = 'full_size_description',

  // Transcript
  TOP_SCORE = 'top score',

  // File
  DEFAULT = 'default',
  WORD_COUNT = 'word count',
  TIME_TO_READ = 'time to read',

  // Transcript or File
  ITEMS = 'items',
}

export enum EvidenceItemInsightTypeIcon {
  // Video
  DURATION = 'schedule',

  // Grade
  PERCENTILE = 'bar_chart',
  AVERAGE_SCORE = 'show_chart',

  // Transcript
  TOP_SCORE = 'emoji_events',

  // File
  WORD_COUNT = 'format_list_numbered',
  TIME_TO_READ = 'schedule',

  // Transcript or File
  ITEMS = 'format_list_bulleted',
}
