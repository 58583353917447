<accredible-dialog-template *transloco="let t; read: 'enable-mfa-prompt-dialog'"
  class="enable-mfa-prompt-dialog"
  [showActions]="false"
  [formGroup]="formGroup"
  (btnCancelClick)="close(false)">
  <div class="info-wrapper">
    <img src="/assets/images/hints-and-guidance/one-time-password.svg"
      alt="">

    <div class="title">
      {{ t('title') }}
    </div>

    <div class="message"
      [innerHtml]="t('message')"></div>
  </div>

  <div class="actions-wrapper">
    <button mat-stroked-button
      [attr.aria-label]="t('aria_not_now')"
      (click)="close(false)">
      {{ t('not_now') }}
    </button>

    <button mat-flat-button
      color="primary"
      [attr.aria-label]="t('aria_enable')"
      (click)="close(true)">
      {{ t('enable') }}
    </button>
  </div>

  <div class="dont-show-again-wrapper">
    <accredible-checkbox-input [formControlRef]="getFormControl(formGroup, 'dontShow')">
      <strong>
        {{ t('dont_show_again') }}
      </strong>
    </accredible-checkbox-input>
  </div>
</accredible-dialog-template>
