import { ColorConfiguration } from './theme-generator-color.model';
import { ComponentsConfiguration } from './theme-generator-components.model';
import { HeaderFooterConfiguration } from './theme-generator-header-footer.model';
import { MarginsPaddingConfiguration } from './theme-generator-margins.model';
import { MetaConfiguration } from './theme-generator-meta.model';
import { TypographyConfiguration } from './theme-generator-typography.model';

export interface ThemeConfiguration {
  themeDomain?: string;
  themeId?: string;
  name?: string;
  lastEdited?: number;
  color?: ColorConfiguration;
  headerFooter?: HeaderFooterConfiguration;
  marginsPadding?: MarginsPaddingConfiguration;
  components?: ComponentsConfiguration;
  meta?: Partial<MetaConfiguration>;
  typography?: TypographyConfiguration;
  newTheme?: boolean;
  customHeader?: boolean;
  customFooter?: boolean;
}

export interface ThemeObject {
  color: ColorConfiguration;
  headerFooter: HeaderFooterConfiguration;
  marginsPadding: MarginsPaddingConfiguration;
  components: ComponentsConfiguration;
  meta: Partial<MetaConfiguration>;
  typography: TypographyConfiguration;
}

export interface ThemeJSONFile {
  name: string;
  config: ThemeConfiguration;
  updatedAt: number;
  cssFileUrl: string;
}
