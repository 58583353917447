export enum ACCREDIBLE_MFA_ERROR_STATUS {
  INVALID_OTP = 'invalid_otp',
  INVALID_MFA_SESSION = 'invalid_mfa_session',
  NOT_CONFIGURED = 'mfa_not_configured',
  RESET_CODE_EXPIRED = 'reset_code_expired',
}

export const ACCREDIBLE_MFA_SESSION_TOKEN_KEY = 'mfa_session_token';

export interface AccredibleMfaSettings {
  id: string;
  configured: boolean;
  method: 'authenticator'; // only authenticator is supported for now
  reset_code?: string;
}
