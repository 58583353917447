<div class="version">{{ version }}</div>

<mat-sidenav-container>
  <accredible-cookies-banner *ngIf="!hasCustomCookieBanner"
    [isRecipientPortal]="true">
  </accredible-cookies-banner>

  <!-- This is only used inside TG iframe -->
  <accredible-iframe-navigation *ngIf="isInThemeGeneratorPreviewIframe">
  </accredible-iframe-navigation>

  <header #headerTag
    hidden>
    <ng-container #headerContainer></ng-container>
  </header>

  <main id="main-content"
    tabindex="-1">
    <router-outlet></router-outlet>
  </main>

  <footer>
    <ng-container #footerContainer></ng-container>
  </footer>
</mat-sidenav-container>
