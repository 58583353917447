import { AccredibleUser } from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccredibleUserApiService {
  private _userSubject$ = new BehaviorSubject<AccredibleUser>(null);
  private _onLogoutSubject$ = new BehaviorSubject<boolean>(false);
  user$ = this._userSubject$.asObservable();
  onLogout$ = this._onLogoutSubject$.asObservable();

  isUserSignedIn(): boolean {
    return !!this._userSubject$.getValue();
  }

  getUser(): AccredibleUser {
    return this._userSubject$.getValue();
  }

  setUser(user: AccredibleUser): void {
    this._userSubject$.next(user);
    this._onLogoutSubject$.next(false);
  }

  deleteUser(): void {
    this._userSubject$.next(null);
    this._onLogoutSubject$.next(true);
  }

  logout(): void {
    this._userSubject$.next(null);
    this._onLogoutSubject$.next(true);
  }
}
