import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';

export const credentialNetAliases = [
  'credential.net',
  'www.credential.net',
  'eu.credential.net',
  'red.credential.net',
  'green.credential.net',
  'blue.credential.net',
  'test.credential.net',
  'sandbox.credential.net',
  'v2.credential.net',
  'rp.theming.accredible.com',
];

export class WhitelabelHelper {
  static isCustomDomain(): boolean {
    const hostname = window.location.hostname;
    // Allow localhost and sandbox.credential.net to have all privileges
    return (
      !WindowHelper.isLocalhost() &&
      !WindowHelper.isSandboxSite() &&
      !credentialNetAliases.includes(hostname)
    );
  }
}
