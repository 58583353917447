import { AccredibleGroup } from './group.model';

export interface GroupCollection {
  id: string;
  name: string;
  description: string;
  organization_id: number;
  public: boolean;
}

export interface GroupCollectionIssuer extends GroupCollection {
  group: AccredibleGroup;
  groups_count: number;
}
