import { AccrediblePageData, AccrediblePageParams } from '@accredible-frontend-v2/models';
import { createAction, props } from '@ngrx/store';
import { MyLearningPathwayEnrolment } from '../../models/my-learning.model';

export const LOAD_MY_LEARNING_PATHWAY_ENROLMENTS =
  '[My Learning Pathway Enrolments] Load Enrolments';
export const LOAD_MY_LEARNING_PATHWAY_ENROLMENTS_SUCCESS =
  '[My Learning Pathway Enrolments] Load Enrolments Success';
export const LOAD_MY_LEARNING_PATHWAY_ENROLMENTS_FAILURE =
  '[My Learning Pathway Enrolments] Load Enrolments Failure';

export const loadMyLearningPathwayEnrolments = createAction(
  LOAD_MY_LEARNING_PATHWAY_ENROLMENTS,
  props<{ username: string; pageParams: AccrediblePageParams }>(),
);
export const loadMyLearningPathwayEnrolmentsSuccess = createAction(
  LOAD_MY_LEARNING_PATHWAY_ENROLMENTS_SUCCESS,
  props<{
    myLearningPathwayEnrolmentsPageData: AccrediblePageData<MyLearningPathwayEnrolment>;
    pageParams: AccrediblePageParams;
  }>(),
);
export const loadMyLearningPathwayEnrolmentsFailure = createAction(
  LOAD_MY_LEARNING_PATHWAY_ENROLMENTS_FAILURE,
  props<{ error: any }>(),
);
