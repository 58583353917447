import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { skip, take } from 'rxjs/operators';

// @Source - https://github.com/DethAriel/ng-recaptcha/issues/25#issuecomment-334311155

declare const grecaptcha: any;

@Injectable()
export class RecaptchaDynamicLanguageLoaderService {
  private static _ready: BehaviorSubject<any>;

  ready: Observable<any>;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _language: AccredibleLanguageService,
  ) {
    // First language on load
    this._language.languageCode$.pipe(take(1)).subscribe((languageCode) => {
      this._init(languageCode);
      this.ready = RecaptchaDynamicLanguageLoaderService._ready.asObservable();
    });

    // On change language
    this._language.languageCode$.pipe(skip(1)).subscribe((languageCode) => {
      RecaptchaDynamicLanguageLoaderService._ready.next(null);
      this._init(languageCode);
    });
  }

  private _init(languageCode: string) {
    if (RecaptchaDynamicLanguageLoaderService._ready) {
      if (RecaptchaDynamicLanguageLoaderService._ready.getValue()) {
        return;
      }
    } else {
      RecaptchaDynamicLanguageLoaderService._ready = new BehaviorSubject<any>(null);
      (<any>window).ng2recaptchaLoaded = () => {
        RecaptchaDynamicLanguageLoaderService._ready.next(grecaptcha);
      };
    }

    const script = this._document.createElement('script') as HTMLScriptElement;
    script.innerHTML = '';
    const langParam = languageCode ? '&hl=' + languageCode : '';
    script.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=ng2recaptchaLoaded${langParam}`;
    script.async = true;
    script.defer = true;
    this._document.head.appendChild(script);
  }
}
