export interface DirectorySettings {
  accredible_setup_complete?: boolean;
  id?: number;
  name: string;
  visible: boolean;
  published: boolean;
  domain: string;
  users_visible_by_default: boolean;
  credential_visibility: any;
  include_expired_credentials: boolean;
  filters: DefaultFilters;
  eligibility_sources: EligibilitySource[];
  all_eligible_groups_searchable: boolean;
  // In the response this is a SearchableGroup[] but in the request it needs to be a number[]
  searchable_groups: SearchableGroup[] | number[];
  custom_attributes: CustomAttribute[];
}

export interface SearchableGroup {
  id: number;
  name?: string;
  course_name: string;
}

export interface EligibilitySource {
  id: number;
  name: string;
  course_name?: string;
  type?: EligibilityType;
  department?: DirectoryDepartment;
  organization?: {
    id: number;
    name: string;
  };
}

export enum CustomAttributeType {
  SELECT_ONE = 'select_one',
  SELECT_ANY = 'select_any',
  TEXT = 'text',
  BOOLEAN = 'boolean',
}

export interface CustomAttributeFilter {
  id: number;
  value: CustomAttributeValue;
}

export interface CustomAttribute {
  id: number;
  label: string;
  type: CustomAttributeType;
  searchable: boolean;
  value?: CustomAttributeValue;
  options?: CustomAttributeOption[];
  description?: string;
  // User value is used only on the Accounts property to provide the current value for the custom attribute
  user_value?: CustomAttributeUserValue;
}

export type CustomAttributeValue = string | boolean | number | number[];

export interface CustomAttributeUserValue {
  id: number;
  value: CustomAttributeValue;
  // Accounts only
  spotlight_directory_custom_attribute_id?: number;
}

export interface CustomAttributeOption {
  id: number;
  label: string;
  // The spotlight_directory_custom_attribute_id property is only used on Accounts
  // to update the users custom attribute value
  spotlight_directory_custom_attribute_id?: number;
}

export interface DirectoryDepartment {
  id: number;
  name: string;
}

export interface DefaultFilters {
  name: boolean;
  groups: boolean;
  location: boolean;
  skills: boolean;
  availability: boolean;
  organizations: boolean;
}

export enum EligibilityType {
  ORGANIZATION = 'Organization',
  GROUP = 'Group',
}
