export const sortObjectAscending = (
  a: { [key: string]: any },
  b: { [key: string]: any },
  key: string,
): number => {
  let aValue = a[key];
  let bValue = b[key];

  // If dealing with strings we need to lowercase them,
  // otherwise it would order uppercase starting strings before lowercase
  if (typeof a[key] === 'string' && typeof b[key] === 'string') {
    aValue = aValue.toLowerCase();
    bValue = bValue.toLowerCase();
  }

  if (aValue > bValue) {
    return 1;
  }
  if (aValue < bValue) {
    return -1;
  }
  return 0;
};

export const sortObjectDescending = (
  a: { [key: string]: any },
  b: { [key: string]: any },
  key: string,
): number => {
  let aValue = a[key];
  let bValue = b[key];

  // If dealing with strings we need to lowercase them,
  // otherwise it would order uppercase starting strings after lowercase
  if (typeof a[key] === 'string' && typeof b[key] === 'string') {
    aValue = aValue.toLowerCase();
    bValue = bValue.toLowerCase();
  }

  if (aValue > bValue) {
    return -1;
  }
  if (aValue < bValue) {
    return 1;
  }
  return 0;
};
