<accredible-dialog-template [btnActionText]="btnActionText"
  [btnActionColor]="btnActionColor"
  [alignActions]="'center'"
  [btnCancelText]="btnCancelText"
  [disableBtnAction]="checkboxText && !checkboxFormControl.getRawValue()"
  (btnActionClick)="btnActionClick.emit()">

  <div class="confirm-dialog-wrapper">
    <img *ngIf="image"
      [src]="image"
      alt="">

    <h1 class="mat-h3">{{ title }}</h1>

    <p [innerHTML]="text"></p>

    <form *ngIf="checkboxText">
      <accredible-checkbox-input data-cy="input-confirm-dialog-checkbox"
        [formControlRef]="checkboxFormControl">
        {{ checkboxText }}
      </accredible-checkbox-input>
    </form>
  </div>

</accredible-dialog-template>
