import { AccredibleAuthenticatedGuard } from '@accredible-frontend-v2/guards/authenticated';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileOwnerGuard } from '../containers/credential-share/guard/profile-owner.guard';
import { RetrieveCredentialsRedirectGuard } from './guards/external-redirect/retrieve-credentials-redirect.guard';
import { IssuerPageGuard } from './guards/issuer-page/issuer-page.guard';
import { MyLearningPathwayEnrolmentsGuard } from './guards/my-learning-pathway-enrolments/my-learning-pathway-enrolments.guard';
import { OneTimeAuthenticatedGuard } from './guards/one-time-authenticated/one-time-authenticated.guard';
import { SSOAuthenticatedGuard } from './guards/sso-authenticated/sso-authenticated.guard';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [OneTimeAuthenticatedGuard],
    children: [], // This is required as the redirection is handled by the canActivate and we have no component
  },
  {
    path: 'user/jwt',
    canActivate: [SSOAuthenticatedGuard],
    children: [], // This is required as the redirection is handled by the canActivate and we have no component
  },
  {
    path: 'retrieve-credentials',
    canActivate: [RetrieveCredentialsRedirectGuard],
    children: [], // This is required as the redirection is handled by the canActivate and we have no component
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('../containers/welcome/welcome.container.module').then(
        (m) => m.WelcomeContainerModule,
      ),
  },
  // This route is only used to generate translated email templates
  // {
  //   path: 'be-email-templates',
  //   loadChildren: () =>
  //     import('../containers/be-email-templates/be-email-templates.container.module').then(
  //       (m) => m.BeEmailTemplatesContainerModule,
  //     ),
  // },
  {
    path: 'embed',
    children: [
      {
        path: ':idOrUuid',
        redirectTo: '/:idOrUuid',
        pathMatch: 'full',
      },
      {
        path: 'profile/:username/wallet',
        loadChildren: () =>
          import('../containers/wallet-embed/wallet-embed.container.module').then(
            (m) => m.WalletEmbedContainerModule,
          ),
      },
      {
        path: '**',
        redirectTo: '/welcome',
      },
    ],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('../containers/profile/profile.container.module').then(
        (m) => m.ProfileContainerModule,
      ),
  },
  {
    path: 'profile/:username/share',
    canActivate: [AccredibleAuthenticatedGuard, ProfileOwnerGuard],
    loadChildren: () =>
      import('../containers/credential-share/credential-share.container.module').then(
        (m) => m.CredentialShareContainerModule,
      ),
  },
  {
    path: 'my-learning',
    canActivate: [AccredibleAuthenticatedGuard, MyLearningPathwayEnrolmentsGuard],
    loadChildren: () =>
      import('../containers/my-learning/my-learning.container.module').then(
        (m) => m.MyLearningContainerModule,
      ),
  },
  {
    path: 'issuer/:id/pathways/:uuid',
    canActivate: [IssuerPageGuard],
    loadChildren: () =>
      import('../containers/pathways/pathways.container.module').then(
        (m) => m.PathwaysContainerModule,
      ),
  },
  {
    path: 'issuer/:id/pathway-track/:uuid',
    canActivate: [IssuerPageGuard],
    loadChildren: () =>
      import('../containers/pathway-tracks/pathway-tracks.container.module').then(
        (m) => m.PathwayTracksContainerModule,
      ),
  },
  {
    path: 'issuer/:id/pathways/:uuid/enrolment/:username',
    canActivate: [IssuerPageGuard],
    loadChildren: () =>
      import('../containers/pathways/pathways.container.module').then(
        (m) => m.PathwaysContainerModule,
      ),
  },
  {
    path: 'issuer/:id',
    canActivate: [IssuerPageGuard],
    loadChildren: () =>
      import('../containers/issuer/issuer.container.module').then((m) => m.IssuerContainerModule),
  },
  {
    path: 'group',
    loadChildren: () =>
      import('../containers/group/group.container.module').then((m) => m.GroupContainerModule),
  },
  {
    path: 'group-collection/:orgId/collection/:collectionId',
    loadChildren: () =>
      import('../containers/group-collection/group-collection.container.module').then(
        (m) => m.GroupCollectionContainerModule,
      ),
  },
  {
    path: 'site-map',
    loadChildren: () =>
      import('../containers/site-map/site-map.container.module').then(
        (m) => m.SiteMapContainerModule,
      ),
  },
  {
    path: 'no-credentials',
    loadChildren: () =>
      import('../containers/no-credentials/no-credentials.container.module').then(
        (m) => m.NoCredentialsContainerModule,
      ),
  },
  {
    path: 'jwt-expired',
    loadChildren: () =>
      import('../containers/jwt-expired/jwt-expired.container.module').then(
        (m) => m.JwtExpiredContainerModule,
      ),
  },
  {
    path: 'user/incomplete_credential',
    redirectTo: 'incomplete_credential',
    pathMatch: 'full',
  },
  {
    path: 'incomplete_credential',
    loadChildren: () =>
      import('../containers/incomplete-credential/incomplete-credential.container.module').then(
        (m) => m.IncompleteCredentialContainerModule,
      ),
  },
  {
    path: 'lti-launch-error',
    loadChildren: () =>
      import('../containers/lti-launch-error/lti-launch-error.container.module').then(
        (m) => m.LtiLaunchErrorContainerModule,
      ),
  },
  {
    path: 'suggestions',
    loadChildren: () =>
      import('../containers/suggestions/suggestions.container.module').then(
        (m) => m.SuggestionsContainerModule,
      ),
  },
  {
    path: '404',
    loadChildren: () =>
      import('../containers/rp-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
  {
    path: 'example',
    redirectTo: '10000005',
    pathMatch: 'full',
  },
  {
    path: ':idOrUuid',
    loadChildren: () =>
      import('../containers/credential/credential.container.module').then(
        (m) => m.CredentialContainerModule,
      ),
  },
  {
    path: ':uuid/transcript-letter',
    loadChildren: () =>
      import('../containers/transcript-letter/transcript-letter.container.module').then(
        (m) => m.TranscriptLetterContainerModule,
      ),
  },
  {
    path: ':uuid/wallet-pass',
    loadChildren: () =>
      import('../containers/wallet-pass/wallet-pass.container.module').then(
        (m) => m.WalletPassContainerModule,
      ),
  },
  {
    path: ':uuid/edit',
    canActivate: [SSOAuthenticatedGuard],
    children: [], // This is required as the redirection is handled by the canActivate and we have no component
  },
  {
    path: '**',
    loadChildren: () =>
      import('../containers/rp-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class RecipientPortalRoutingModule {}
