export interface AccredibleOrganizationSettings {
  credential_visibility: 'department' | 'organization' | null;
  department_id: number;
  domain: string;
  logo: string;
  organization_departments: number[];
  whitelabel_active: string;
  url: string;
  name: string;
}
