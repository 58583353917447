import { AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TrackLink } from './tracking.model';

@Injectable({
  providedIn: 'root',
})
export class AccredibleTrackingApiService extends AccredibleApiService {
  private static _bugsnagNotify(endpoint: string): Observable<never> {
    Bugsnag.notify('Failed to track: ' + endpoint);
    return of();
  }

  linkedInShare(credentialId: number): void {
    const endpoint = '/v1/credential-net/credential_analytics/linkedin_certificate_shared';
    this._post(endpoint, { credential_id: credentialId })
      .pipe(catchError(() => AccredibleTrackingApiService._bugsnagNotify(endpoint)))
      .subscribe();
  }

  linkedInAddToProfile(credentialId: number): void {
    const endpoint = '/v1/credential-net/credential_analytics/linkedin_certificate_added';
    this._post(endpoint, { credential_id: credentialId })
      .pipe(catchError(() => AccredibleTrackingApiService._bugsnagNotify(endpoint)))
      .subscribe();
  }

  trackExternalLink(trackLink: TrackLink): void {
    const endpoint = '/v1/credential-net/credential_analytics/track_link';
    this._post(endpoint, trackLink)
      .pipe(catchError(() => AccredibleTrackingApiService._bugsnagNotify(endpoint)))
      .subscribe();
  }
}
