import { AccredibleBaseFormDialogComponent } from '@accredible-frontend-v2/dialog';
import { Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface PromptDialogResult {
  dontShow: boolean;
  enable: boolean;
}

@Component({
  templateUrl: './enable-mfa-prompt-dialog.component.html',
  styleUrls: [`./enable-mfa-prompt-dialog.component.scss`],
})
export class EnableMfaPromptDialogComponent extends AccredibleBaseFormDialogComponent<
  EnableMfaPromptDialogComponent,
  { dontShow: boolean }
> {
  constructor(
    public dialogRef: MatDialogRef<EnableMfaPromptDialogComponent>,
    protected readonly _el: ElementRef<HTMLElement>,
    private readonly _fb: UntypedFormBuilder,
  ) {
    super(dialogRef, _el);

    this.formGroup = this._fb.group({
      dontShow: [true],
    });
  }

  close(enable: boolean): void {
    this.dialogRef.close(<PromptDialogResult>{
      dontShow: this.formGroup.get('dontShow').value,
      enable,
    });
  }
}
