import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CredentialsEffects } from './credentials.effects';
import * as CredentialsStore from './credentials.reducer';
import { CredentialsService } from './credentials.service';

@NgModule({
  imports: [
    StoreModule.forFeature(CredentialsStore.credentialsFeatureKey, CredentialsStore.reducer),
    EffectsModule.forFeature([CredentialsEffects]),
  ],
  exports: [],
  declarations: [],
  providers: [CredentialsService],
})
export class CredentialsStoreModule {}
