export interface AccredibleTopEmployersData {
  data: {
    leaderboard: TopEmployerData[];
  };
  location: string;
  country_code: string;
  adzunaDomain?: string;

  // Local
  groupId: number;
}

interface TopEmployerData {
  count: number;
  canonical_name: string;
}

export interface AccredibleSalaryHistoryData {
  data: {
    month: unknown;
  };
  location: string;
  country_code: string;

  // Local
  groupId: number;
}

export interface AccredibleJobTitlesData {
  data: {
    results: [string, number][];
    count: number;
    mean: number;
  };
  location: string;
  country_code: string;

  // Local
  groupId: number;
}
