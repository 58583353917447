export enum AccredibleEditorMenuCommand {
  TOGGLE_BOLD = 'toggleBold',
  TOGGLE_ITALIC = 'toggleItalic',
  TOGGLE_UNDERLINE = 'toggleUnderline',
  TOGGLE_ORDERED_LIST = 'toggleOrderedList',
  TOGGLE_BULLET_LIST = 'toggleBulletList',
}

export enum AccredibleEditorOption {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  LINK = 'link',
  ORDERED_LIST = 'orderedList',
  BULLET_LIST = 'bulletList',
}

export interface AccredibleEditorMenuButton {
  customAction?: () => void;
  action?: AccredibleEditorMenuCommand;
  node: AccredibleEditorOption;
  icon: string;
  className?: string;
  ariaLabel?: string;
}

export enum AccredibleEditorMenuType {
  FIXED = 'fixed',
  BUBBLE = 'bubble',
}
