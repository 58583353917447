import { AccredibleAccountsRedirectionService } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAuthenticationApiService } from '@accredible-frontend-v2/services/authentication';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AccredibleAuthenticatedGuard implements CanActivate {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _accountsRedirection: AccredibleAccountsRedirectionService,
    private readonly _authenticationApi: AccredibleAuthenticationApiService,
  ) {}

  canActivate(): boolean {
    if (!!this._authenticationApi.getSessionToken()) {
      // User is logged in
      return true;
    }

    // User is not logged in, go to login page
    this._document.location.href = this._accountsRedirection.getLoginUrlHref();
    return false;
  }
}
