import Bugsnag from '@bugsnag/js';

export class AccredibleUrlHelper {
  /**
   * This method returns a valid URL or throws an error if failed to construct the URL.
   *
   * If http or https is not present in the URL,
   * this method adds http if the URL includes the word localhost or https if not
   */
  static createUrl(url: string, context: string): URL {
    try {
      return new URL(
        !url.startsWith('http') && url.includes('localhost')
          ? `http://${url}`
          : !url.startsWith('http')
          ? `https://${url}`
          : url,
      );
    } catch (e) {
      Bugsnag.notify(`Failed to construct URL: ${url} Context: ${context}`);

      // Always protect your URL with url?. if you need to use any property of the URL
      return null;
    }
  }

  static isValidUrl(urlString: string): boolean {
    try {
      new URL(urlString);
      return true;
    } catch (err) {
      return false;
    }
  }
}
