import { AccredibleCookiesBannerComponentModule } from '@accredible-frontend-v2/components/cookies-banner';
import { AccredibleIframeNavigationComponentModule } from '@accredible-frontend-v2/components/iframe-navigation';
import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleAccountsRedirectionServiceModule } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAuthenticationServiceModule } from '@accredible-frontend-v2/services/authentication';
import { AccredibleBrowserHistoryServiceModule } from '@accredible-frontend-v2/services/browser-history';
import { AccredibleBrowserStorageServiceModule } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesServiceModule } from '@accredible-frontend-v2/services/cookies';
import {
  AccredibleLanguageServiceModule,
  CUSTOM_DATE_FORMATS,
  CustomDatePickerAdapter,
} from '@accredible-frontend-v2/services/language';
import { AccredibleSeoServiceModule } from '@accredible-frontend-v2/services/seo';
import { ThemeGeneratorModule } from '@accredible-frontend-v2/services/theme-generator';
import { AccredibleTrackingApiServiceModule } from '@accredible-frontend-v2/services/tracking';
import { AccredibleUserServiceModule } from '@accredible-frontend-v2/services/user';
import { AccredibleToastModule } from '@accredible-frontend-v2/toast';
import { startBugsnag } from '@accredible-frontend-v2/utils/bugsnag-helper';
import { AccredibleGlobalBugsnagErrorHandler } from '@accredible-frontend-v2/utils/global-bugsnag-error-handler';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Provider } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CredentialsStoreModule } from '../shared/stores/credentials/credentials-store.module';
import { MyLearningPathwayEnrolmentsStoreModule } from '../shared/stores/my-learning-pathway-enrolments/my-learning-pathway-enrolments-store.module';
import { ThemeHelper } from '../themes/theme.helper';
import { EnableMfaPromptDialogComponentModule } from './components/enable-mfa-prompt-dialog/enable-mfa-prompt-dialog.component.module';
import { OneTimeAuthenticatedGuard } from './guards/one-time-authenticated/one-time-authenticated.guard';
import { RecipientPortalLoadModule } from './recipient-portal-load.module';
import { RecipientPortalRoutingModule } from './recipient-portal-routing.module';
import { RecipientPortalContainer } from './recipient-portal.container';
import { metaReducers, reducers } from './stores/reducer';

const CORE_MODULES = [
  RecipientPortalLoadModule,
  RecipientPortalRoutingModule,
  ThemeGeneratorModule,
];

const CORE_STORE_MODULES = [CredentialsStoreModule, MyLearningPathwayEnrolmentsStoreModule];

const CORE_ACCREDIBLE_LIBS_MODULES = [
  AccredibleAccountsRedirectionServiceModule,
  AccredibleTrackingApiServiceModule,
  AccredibleBrowserHistoryServiceModule,
  AccredibleBrowserStorageServiceModule,
  AccredibleCookiesServiceModule,
  AccredibleCookiesBannerComponentModule,
  AccredibleSeoServiceModule,
  AccredibleUserServiceModule,
  AccredibleAuthenticationServiceModule.forRoot({ app: 'rp' }),
  AccredibleLanguageServiceModule.forRoot(
    accredibleCustomThemesMetadata[ThemeHelper.getTheme()].languages,
  ),
  AccredibleToastModule.forRoot({
    // The toast durations are 10s/0s due to accessibility considerations,
    // some visual accessible users need more time to see the message
    duration: 10000,
    errorDuration: 0,
  }),
  AccredibleIframeNavigationComponentModule,
];

const CORE_NPM_MODULES = [RecaptchaV3Module];

const MATERIAL_MODULES = [MatSidenavModule];

const DIALOG_MODULES = [EnableMfaPromptDialogComponentModule];

const CUSTOM_MATERIAL_DATE_ADAPTER = [
  {
    provide: DateAdapter,
    useClass: CustomDatePickerAdapter,
  },
  { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
];

const CUSTOM_MAT_TOOLTIP_OPTIONS = [
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: {
      position: 'above',
      showDelay: '500',
      hideDelay: '200',
      touchendHideDelay: '200',
    },
  },
];

const PROVIDERS = <Provider[]>[
  OneTimeAuthenticatedGuard,

  ...CUSTOM_MATERIAL_DATE_ADAPTER,
  ...CUSTOM_MAT_TOOLTIP_OPTIONS,

  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptchaV3SiteKey,
  },
];

if (environment.bugsnagApiKey) {
  const bugsnagClient = startBugsnag('Recipient Portal');
  PROVIDERS.push({
    provide: ErrorHandler,
    useFactory() {
      return new AccredibleGlobalBugsnagErrorHandler(bugsnagClient);
    },
  });
}

@NgModule({
  declarations: [RecipientPortalContainer],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,

    ...CORE_MODULES,
    ...CORE_STORE_MODULES,
    ...CORE_ACCREDIBLE_LIBS_MODULES,
    ...CORE_NPM_MODULES,
    ...MATERIAL_MODULES,
    ...DIALOG_MODULES,

    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    environment.type !== 'production'
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    EffectsModule.forRoot(),
  ],
  providers: PROVIDERS,
  bootstrap: [RecipientPortalContainer],
})
export class RecipientPortalModule {}
