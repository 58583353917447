import { environment } from '@accredible-frontend-v2/envs';

export const isValidCredentialUuidOrID = (id: number | string): boolean => {
  // Valid uuid
  const uuidRegex = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/,
  );

  if (typeof id === 'number') {
    id = id.toString();
  }

  return uuidRegex.test(id) || isValidCredentialID(id);
};

export const isValidCredentialID = (id: number | string): boolean => {
  // Valid encoded id or a plain id with at least 8 characters for non-EU credentials
  // and any characters for EU credentials
  const idRegex = new RegExp(
    environment.prefix.includes('eu') || environment.prefix.includes('qa')
      ? /^[0-9a-zA-Z]+$/
      : /^[0-9a-zA-Z]{8,}$/,
  );

  if (typeof id === 'number') {
    id = id.toString();
  }

  return idRegex.test(id);
};

export const isUuidSlashEditRoute = (pathname: string): boolean => {
  const regex = new RegExp(/\/(.*)\/edit/);

  if (regex.test(pathname)) {
    return isValidCredentialUuidOrID(regex.exec(pathname)[1]);
  }

  return false;
};
