import { RGBA } from 'ngx-color/helpers/color.interfaces';

export interface Palette {
  main: string;
  lighter: string;
  darker: string;
  independent?: boolean;
}

export interface GeneralColors extends BaseColorPalette {
  primary: Palette;
  accent: Palette;
  warn: Palette;
  subtle: Palette;
}

export interface AlertColors extends BaseColorPalette {
  text: Palette;
  background: Palette;
}

export interface IndicatorColors extends BaseThemeColor {
  link: ThemeColor;
  success: ThemeColor;
  verified: ThemeColor;
  blockchain: ThemeColor;
}

export interface BorderColors extends BaseThemeColor {
  border: ThemeColor;
  inputBorder: ThemeColor;
}

export interface GhostColors extends BaseThemeColor {
  ghost: ThemeColor;
  ghostAlt: ThemeColor;
}

export interface OutlineColors extends BaseThemeColor {
  outline: ThemeColor;
  outlineAlt: ThemeColor;
  outlineGs: ThemeColor;
}

export interface TextAndBackgroundColor {
  text: string;
  background: string;
}

export interface ColorVariables {
  success: ThemeColor;
  link: ThemeColor;
  outline: ThemeColor;
  outlineAlt: ThemeColor;
  outlineGs: ThemeColor;
  hover: ThemeColor;
  border: ThemeColor;
  inputBorder: ThemeColor;
  ghost: ThemeColor;
  ghostAlt: ThemeColor;
  chip: ThemeColor;
  chipText: ThemeColor;
  verified: ThemeColor;
  blockchain: ThemeColor;
  credentialBannerBackground: ThemeColor;
  credentialBannerSelected: ThemeColor;
}

export interface ColorConfiguration {
  generalColors: GeneralColors;
  indicatorColors: IndicatorColors;
  borderColors: BorderColors;
  alertColors: AlertColors;
  ghostColors: GhostColors;
  inputColors?: TextAndBackgroundColor;
  outlineColors: OutlineColors;
  // TODO: remove properties below and their and respective types once they've been moved to correct configuration
  lightTextAndBackgroundColor: TextAndBackgroundColor;
  darkTextAndBackgroundColor: TextAndBackgroundColor;
  colorVariables: ColorVariables;
}

export type ThemeColor = string | RGBA;

export interface BaseColorPalette {
  [key: string]: Palette;
}

export interface BaseThemeColor {
  [key: string]: ThemeColor;
}
