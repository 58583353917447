import { AccredibleAccountsRedirectionService } from '@accredible-frontend-v2/services/accounts-redirection';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RetrieveCredentialsRedirectGuard implements CanActivate {
  constructor(private _accountsRedirection: AccredibleAccountsRedirectionService) {}

  canActivate(): boolean {
    window.location.href = this._accountsRedirection.getRetrieveCredentialsUrl();
    return false;
  }
}
