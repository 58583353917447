import { AccredibleCheckboxInputComponentModule } from '@accredible-frontend-v2/forms/checkbox-input';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfirmDialogData } from '../confirm-dialog/confirm-dialog.model';
import { AccredibleDialogTemplateComponentModule } from '../dialog-template/dialog-template.component.module';

@Component({
  selector: 'accredible-confirm-dialog-template',
  standalone: true,
  imports: [
    CommonModule,
    AccredibleDialogTemplateComponentModule,
    AccredibleCheckboxInputComponentModule,
  ],
  templateUrl: './confirm-dialog-template.component.html',
  styleUrls: [`./confirm-dialog-template.component.scss`],
})
export class AccredibleConfirmDialogTemplateComponent implements ConfirmDialogData {
  @Input()
  title: string;
  @Input()
  text: string;
  @Input()
  btnActionText: string;
  @Input()
  btnActionColor: 'primary' | 'warn' = 'primary';
  @Input()
  btnCancelText: string;
  @Input()
  image: string;
  @Input()
  checkboxText: string;
  @Input()
  ariaLabel: string;

  @Output()
  btnActionClick = new EventEmitter<boolean>();

  checkboxFormControl = new FormControl<boolean>(false);
}
