import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'accredible-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AccredibleIconComponent {
  @Input()
  icon = '';
  @Input()
  supportRtl = false;
  @Input()
  ariaHidden = true;

  @HostBinding('class')
  get classes(): string {
    return this.supportRtl ? 'support-rtl' : '';
  }
}
