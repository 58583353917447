import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

export const ProfileOwnerGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): boolean | UrlTree => {
  const router = inject(Router);
  const userApi = inject(AccredibleUserApiService);
  const loggedInUsername = userApi.getUser().username;

  if (route.params.username === loggedInUsername) {
    return true;
  }

  if (loggedInUsername) {
    return router.createUrlTree([`profile/${loggedInUsername}/share`]);
  }

  return false;
};
