import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { MyLearningApiService } from '../../services/my-learning/my-learning.service';
import * as MyLearningPathwayEnrolmentsActions from './my-learning-pathway-enrolments.actions';

@Injectable()
export class MyLearningPathwayEnrolmentsEffects {
  loadMyLearningPathwayEnrolments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MyLearningPathwayEnrolmentsActions.loadMyLearningPathwayEnrolments),
      exhaustMap(({ username, pageParams }) => {
        return this._myLearningService.loadMyLearningPathwayEnrolments(username, pageParams).pipe(
          map((myLearningPathwayEnrolmentsPageData) =>
            MyLearningPathwayEnrolmentsActions.loadMyLearningPathwayEnrolmentsSuccess({
              myLearningPathwayEnrolmentsPageData,
              pageParams,
            }),
          ),
          catchError((error) =>
            of(
              MyLearningPathwayEnrolmentsActions.loadMyLearningPathwayEnrolmentsFailure({
                error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _myLearningService: MyLearningApiService,
  ) {}
}
