export type AccrediblePageData<T> = { meta: AccrediblePageMeta } & { [key: string]: T[] };

export interface AccrediblePageMeta {
  current_page: number;
  total_pages: number;
  next_page?: number | null;
  prev_page?: number | null;
  page_size: number;
  total_count: number;
  max_available?: number;
}

// TODO(Fred): Remove order from this interface and create specific interface that extends this one where order is needed
export interface AccrediblePageParams {
  page?: number;
  page_size?: number;
  order?: string;
}
