import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { ToastComponent } from '../component/toast.component';
import { AccredibleToastData } from '../model/toast.model';
import { AccredibleToastRef } from '../toast-ref';
import { TOAST_DATA } from '../toast-tokens';

export enum ToastAction {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

@Injectable({
  providedIn: 'root',
})
export class AccredibleToastService {
  private _toastRef: AccredibleToastRef;
  private _toastTimeout: ReturnType<typeof setTimeout>;

  constructor(
    private readonly _overlay: Overlay,
    private readonly _injector: Injector,
    private readonly _language: AccredibleLanguageService,
  ) {}

  info(
    message: string,
    action: string = this._language.translate('accredible-toast.ok'),
    duration = 5000,
  ): AccredibleToastRef {
    return this._createToastRef(ToastAction.INFO, message, action, duration);
  }

  success(
    message: string,
    action: string = this._language.translate('accredible-toast.ok'),
    duration = 5000,
  ): AccredibleToastRef {
    return this._createToastRef(ToastAction.SUCCESS, message, action, duration);
  }

  error(
    message: string,
    action: string = this._language.translate('accredible-toast.ok'),
    duration = 10000,
  ): AccredibleToastRef {
    return this._createToastRef(ToastAction.ERROR, message, action, duration);
  }

  dismissCurrentToast(): void {
    this._toastRef?.dismiss();
    clearTimeout(this._toastTimeout);
  }

  private _createToastRef(
    status: ToastAction,
    message: string,
    action: string,
    duration: number,
  ): AccredibleToastRef {
    // If a toast is already created, dismiss it. Only one toast should be on the DOM.
    this.dismissCurrentToast();

    const overlayRef = this._overlay.create({
      panelClass: `accredible-toast-${status}`,
      positionStrategy: this._overlay.position().global().centerHorizontally().bottom('0'),
    });

    // Create toastRef to return
    this._toastRef = new AccredibleToastRef(overlayRef);

    // Create injector to be able to reference the ToastRef from within the component
    const injector = Injector.create({
      parent: this._injector,
      providers: [
        { provide: AccredibleToastRef, useValue: this._toastRef },
        {
          provide: TOAST_DATA,
          useValue: <AccredibleToastData>{ message, action },
        },
      ],
    });

    // Attach component portal to the overlay
    const componentPortal = new ComponentPortal(ToastComponent, null, injector);
    overlayRef.attach(componentPortal).setInput('status', status);

    // If duration is <= 0 the toast will be shown until the user clicks the action button
    if (duration > 0) {
      this._toastTimeout = setTimeout(() => {
        this._toastRef.dismiss();
      }, duration);
    }

    return this._toastRef;
  }
}
