import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { AccredibleToastData } from '../../model/toast.model';

@Component({
  selector: 'accredible-default-toast',
  templateUrl: './default-toast.component.html',
  styleUrls: [`./default-toast.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultToastComponent {
  toastData = <AccredibleToastData>{
    message: '',
    icon: 'info',
  };

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) private readonly _data: AccredibleToastData,
    private readonly _snackBarRef: MatSnackBarRef<DefaultToastComponent>,
  ) {
    this.toastData = {
      ...this.toastData,
      ...this._data,
    };
  }

  close(): void {
    this._snackBarRef.dismiss();
  }
}
