import { environment } from '@accredible-frontend-v2/envs';

export const addMicrosoftClarity = (id: string): void => {
  if (environment.type === 'production' && location.hostname !== 'localhost') {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    scriptTag.innerHTML = `(
      function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      }
    )(window, document, "clarity", "script", "${id}");`;

    document.head.insertBefore(scriptTag, document.head.firstChild);
  }
};

export const setMicrosoftClarityAttribute = (attribute: string, value: any): void => {
  const clarity = (<any>window)['clarity'];
  if (clarity) {
    clarity('set', attribute, value);
  }
};
