import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { AccredibleConfirmDialogComponentModule } from './confirm-dialog/confirm-dialog.component.module';
import { AccredibleDialogService } from './service/dialog.service';

const MATERIAL_MODULES = [MatDialogModule];

@NgModule({
  imports: [AccredibleConfirmDialogComponentModule, ...MATERIAL_MODULES],
  providers: [AccredibleDialogService],
})
export class AccredibleDialogModule {}
