import { ThemeHelperInterface } from '@accredible-frontend-v2/models';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoCourseParams, SeoParams } from './seo.model';

@Injectable({
  providedIn: 'root',
})
export class AccredibleSeoService {
  private _theme: string;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _meta: Meta,
    private readonly _title: Title,
  ) {}

  setTheme(ThemeHelper: ThemeHelperInterface): void {
    this._theme = ThemeHelper.getTheme();
  }

  generateTags(config?: SeoParams): void {
    const themeMetadata: SeoParams = accredibleCustomThemesMetadata[this._theme];

    // default values
    config = {
      title: themeMetadata.title,
      description: themeMetadata.description,
      identity: themeMetadata.identity,
      spiderable: themeMetadata.spiderable,
      image: themeMetadata.image,
      url: '',
      twitterCard: 'summary',
      twitterSite: themeMetadata.twitterSite,
      twitterCreator: themeMetadata.twitterCreator,
      twitterImage: themeMetadata.image,
      ...config,
    };

    // Content
    this._title.setTitle(config.title);
    this._meta.updateTag({
      name: 'author',
      content: config.identity,
    });
    this._meta.updateTag({
      name: 'description',
      content: config.description,
    });

    // Facebook & others
    this._meta.updateTag({ property: 'og:type', content: 'website' });
    this._meta.updateTag({ property: 'og:site_name', content: config.identity });
    this._meta.updateTag({ property: 'og:title', content: config.title });
    this._meta.updateTag({ property: 'og:description', content: config.description });
    this._meta.updateTag({ property: 'og:image', content: config.image || themeMetadata.image });
    this._meta.updateTag({
      property: 'og:url',
      content: `${this._document.location.origin}${config.url}`,
    });

    // Twitter
    this._meta.updateTag({ name: 'twitter:card', content: config.twitterCard });
    this._meta.updateTag({ name: 'twitter:site', content: config.twitterSite });
    this._meta.updateTag({ name: 'twitter:creator', content: config.twitterCreator });
    this._meta.updateTag({ name: 'twitter:title', content: config.title });
    this._meta.updateTag({ name: 'twitter:description', content: config.description });
    this._meta.updateTag({
      name: 'twitter:image',
      content: config.twitterImage || themeMetadata.image,
    });

    // Indexing / Spiders
    this._meta.updateTag({
      name: 'googlebot',
      content: config.spiderable,
    });
    this._meta.updateTag({
      name: 'bingbot',
      content: config.spiderable,
    });
    this._meta.updateTag({
      name: 'robots',
      content: config.spiderable,
    });

    // Tell headless-render-api and Netlify pre-rendering services that meta tags are ready https://headless-render-api.com/docs/triggering-render-completion
    setTimeout(() => {
      (<any>window).prerendercloudReady = true;
      (<any>window).prerenderReady = true;
    }, 100);
  }

  generateCourseSeoScript(course: SeoCourseParams): void {
    const script = this._document.createElement('script') as HTMLScriptElement;
    script.type = 'application/ld+json';
    script.innerHTML = `
      {
        "@context": "https://schema.org",
        "@type": "Course",
        "name": "${course.name}",
        "description": "${course.description}",
        "provider": {
          "@type": "Organization",
          "name": "${course.organization?.name}",
          "sameAs": "${course.organization?.url.split('?')[0]}"
        }
      }
    `;

    this._document.head.appendChild(script);
  }
}
