<div class="accredible-toast-container"
  role="status"
  [attr.data-cy]="'accredible-toast-' + status">
  <span [innerHtml]="data.message"></span>

  <button class="font-normal-semibold"
    (click)="dismiss()">
    {{ data.action }}
  </button>
</div>
