import { PathwaySummaryVersion } from './pathways.model';

export interface PathwayJobTitle {
  uuid: string;
  pathway_version?: PathwaySummaryVersion;
}

export interface PathwaySequence {
  name: string;
  description: string;
  kind: string;
  pathways: PathwayJobTitle[];
  publish_status?: PathwaySequencePublishStatus;
  uuid?: string;
  created_at?: string;
  updated_at?: string;
  published_at?: string;
  organization?: PathwaySequenceOrganization;
}

export interface PathwaySequenceOrganization {
  id: number;
  name: string;
  image_address: string;
  verified: boolean;
}

export enum PathwaySequencePublishStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export type PathwaySequenceMetaData = Pick<PathwaySequence, 'name' | 'description'>;
