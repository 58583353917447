import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccredibleButtonComponent } from './button.component';

@NgModule({
  imports: [CommonModule, RouterModule, AccredibleIconComponentModule],
  exports: [AccredibleButtonComponent],
  declarations: [AccredibleButtonComponent],
})
export class AccredibleButtonComponentModule {}
