import { FocusMonitor } from '@angular/cdk/a11y';
import { ElementRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export class AccredibleBaseDialogComponent<C> {
  constructor(
    public readonly dialogRef: MatDialogRef<C>,
    protected readonly _focusMonitor?: FocusMonitor,
    protected readonly _trigger?: ElementRef<HTMLElement>,
  ) {
    if (_trigger) {
      dialogRef.afterClosed().subscribe(() => {
        _focusMonitor.focusVia(_trigger, 'keyboard');
      });
    }
  }

  close(actioned = false): void {
    this.dialogRef.close(actioned);
  }
}
