import { Sort } from '@angular/material/sort';
import { GroupCollection } from './group-collection.model';
import { AccredibleGroup } from './group.model';
import { AccrediblePageMeta, AccrediblePageParams } from './page.model';

export const AGGREGATIONS_MAX_SIZE = 50;
export const LANGUAGES_AGGREGATION_NAME = 'languagesAggregation';

export interface AccredibleOpenSearchApiResponse<T> {
  hits: T[];
  aggs: AccredibleOpenSearchApiAggregations;
  meta: AccredibleOpenSearchApiResponseMeta;
  diagnostics: Diagnostics;
}

// This interface is used in order to parse the new OpenSearch meta to the AccrediblePageMeta which is supported by our pagination components
export interface AccredibleOpenSearchApiParsedResponse<T>
  extends Omit<AccredibleOpenSearchApiResponse<T>, 'meta'> {
  meta: AccrediblePageMeta;
}

export interface AccredibleOpenSearchApiAggregations {
  [key: string]: AccredibleOpenSearchApiAggregation;
}

export interface AccredibleOpenSearchApiAggregation {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: AccredibleOpenSearchApiAggregationBucket[];
}

export interface AccredibleOpenSearchApiAggregationBucket {
  key: number | string;
  doc_count: number;
}

export interface AccredibleOpenSearchApiResponseMeta {
  count: number;
  from: number;
  size: number;
}

interface Diagnostics {
  took: number;
  max_score: number;
  timed_out: boolean;
}

export interface AccredibleOpenSearchApiRequestBody {
  from: number;
  size: number;
  diagnostics?: boolean;
  return_fields?: string[];
  filter_queries?: (AccredibleOpenSearchApiFilterQuery | AccredibleOpenSearchApiFilterQuery[])[];
  text_search_query?: AccredibleOpenSearchApiTextSearchQuery;
  range_queries?: AccredibleOpenSearchApiDateRangeQuery[];
  aggregations?: AccredibleOpenSearchApiAggregationQuery[];
  sort?: AccredibleOpenSearchApiSortQuery[];
}

export interface AccredibleOpenSearchApiFilterQuery {
  field: string;
  value: string | string[] | number | number[] | Date | boolean;
  boolean_operator: AccredibleOpenSearchApiBooleanOperator;
}

export type AccredibleOpenSearchApiBooleanOperator = 'MUST' | 'MUST_NOT';

export interface AccredibleOpenSearchApiTextSearchQuery {
  fields: string[];
  value: string;
}

export interface AccredibleOpenSearchApiDateRangeQuery {
  field: string;
  query: RangeQuery;
}

interface RangeQuery {
  operator: RangeQueryOperator;
  value: Date | string;
}

export interface AccredibleOpenSearchApiAggregationQuery {
  field: string;
  name: string;
  size: number;
}

export interface AccredibleOpenSearchApiSortQuery {
  field: string;
  order: AccredibleOpenSearchApiSortOrder;
}

export type AccredibleOpenSearchApiSortOrder = 'ASC' | 'DESC';

// Credential List models
export interface CredentialsListFilters extends AccrediblePageParams {
  searchTerm: string;
  searchType: SEARCH_FILTER_OPTIONS;
  selectedGroups: AccredibleGroup[];
  status: BooleanOption;
  visibility: BooleanOption;
  engagement: EngagementFilter;
  dateRange: {
    start: Date;
    end: Date;
  };
  customAttributes: CustomAttributeFilterState[];
  jobId: string;
  sort: Sort;
  departmentId: number;
  languages: string[];
  selectedCollections: GroupCollection[];
}

export interface EngagementFilter {
  recipient_opened?: boolean;
  recipient_engaged?: boolean;
  linkedin_engaged?: boolean;
}

export interface BooleanOption {
  field: string;
  value: boolean;
}

export enum CredentialsListStatusFilter {
  ALL = 'all',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  EXPIRED = 'expired',
  BOUNCED = 'bounced_email',
}

export enum RangeQueryOperator {
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
}

export enum CredentialsListVisibilityFilter {
  ALL = 'all',
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum SEARCH_FILTER_OPTIONS {
  TEXT_FIELDS = 'text',
  IDS_FIELDS = 'ids',
}

export interface CustomAttributeFilterState {
  id: number;
  key: string;
  value: string;
}
