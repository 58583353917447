import {
  AccredibleCredential,
  AccredibleCustomCredential,
  AccredibleEvidenceItem,
  AccredibleEvidenceItemInsights,
  AccredibleOrganizationSettings,
  AccredibleUser,
  PathwayEnrolment,
} from '@accredible-frontend-v2/models';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import {
  CuEvidenceItem,
  LocalEvidenceItem,
} from '../../../containers/credential/models/evidence.model';
import {
  PhysicalAwardBESuccessResponse,
  PhysicalAwardPurchaseIntent,
  PhysicalAwardPurchaseIntentResponse,
} from '../../../containers/credential/models/physical-awards.model';
import { RecommendedGroup } from '../../../containers/credential/models/recommendations.model';
import { Profile } from '../../../containers/profile/models/profile.model';
import { credentialNetAliases } from '../../helpers/whitelabel.helper';
import { RecipientPortalApiService } from '../../services/rp-api/rp-api.service';

export interface NameChangeRequestResponse {
  id: number;
  credential_id: number;
  status: NameChangeRequestStatus;
  current_name: string;
  new_name: string;
  recipient_email: string;
  created_at: Date;
}

export enum NameChangeRequestStatus {
  AUTO_APPROVE = 'auto_approved',
  PENDING = 'pending',
}

@Injectable({
  providedIn: 'root',
})
export class CredentialsService extends RecipientPortalApiService {
  loadOrganizationSettings(): Observable<AccredibleOrganizationSettings> {
    const appDomain =
      credentialNetAliases.includes(window.location.hostname) || WindowHelper.isLocalhost()
        ? 'credential.net'
        : window.location.hostname;
    return this._get(`/v1/credential-net/organizations/${appDomain}/whitelabel_settings`).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  // Mock example - '/all_credentials.json', true
  loadPublicProfile(username: string): Observable<Profile> {
    return this._get(`/v1/credential-net/users/${username}/user_wallet`).pipe(
      map((res) => this._handleResponse(res, 'data')),
      catchError((res) => this._handleError(res, true)),
    );
  }

  // Mock example - '/all_credentials.json', true
  loadPrivateProfile(userId: number): Observable<Profile> {
    return this._get(`/v1/credential-net/users/${userId}/credentials`).pipe(
      map((res) => this._handleResponse(res, 'user')),
      catchError((res) => this._handleError(res, true)),
    );
  }

  // Mock example - '/credential_10000005.json', true
  loadCredential(idOrUuid: number | string, privateKey?: string): Observable<AccredibleCredential> {
    let url = `/v1/recipient/credentials/${idOrUuid}`;
    const searchParams = new URLSearchParams();
    if (privateKey) {
      searchParams.set('key', privateKey);
    }
    url = !!searchParams.toString() ? `${url}?${searchParams.toString()}` : url;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'credential')),
      catchError((res) => this._handleError(res, true)),
    );
  }

  loadCredentialUser(username: string): Observable<AccredibleUser> {
    return this._get(`/v1/credential-net/users/public_identity?username=${username}`).pipe(
      map((res) => this._handleResponse(res, 'user')),
      catchError((res) => this._handleError(res)),
    );
  }

  updateCredentialPrivacy(
    credentialId: number,
    isPrivate: boolean,
  ): Observable<{ private_key: string; url: string }> {
    return this._put(`/v1/credential-net/credentials/${credentialId}/privacy`, {
      private: isPrivate,
    }).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  requestRecipientNameChange(
    credentialId: number,
    updatedName: string,
  ): Observable<HttpResponse<{ recipient_name_change_request: NameChangeRequestResponse }>> {
    return this._post(`/v1/recipient/credentials/${credentialId}/recipient_name_change_requests`, {
      recipient_name_change_request: {
        new_name: updatedName,
      },
    }).pipe(
      map((res) => res),
      catchError((error) => {
        this._handleError(error);
        return throwError(error);
      }),
    );
  }

  // Custom Credential
  loadCustomCredential(uuid: string): Observable<AccredibleCustomCredential> {
    return this._get(`/v1/credential-net/custom_credentials/${uuid}`).pipe(
      map((res) => this._handleResponse(res, 'custom_credential')),
      catchError((res) => this._handleError(res, true)),
    );
  }

  addCustomCredential(
    customCredential: AccredibleCustomCredential,
  ): Observable<AccredibleCustomCredential> {
    return this._post('/v1/credential-net/custom_credentials', {
      custom_credential: customCredential,
    }).pipe(
      map((res) => this._handleResponse(res, 'custom_credential')),
      catchError((res) => this._handleError(res)),
    );
  }

  editCustomCredential(
    customCredential: AccredibleCustomCredential,
  ): Observable<AccredibleCustomCredential> {
    return this._put(`/v1/credential-net/custom_credentials/${customCredential.id}`, {
      custom_credential: customCredential,
    }).pipe(
      map((res) => this._handleResponse(res, 'custom_credential')),
      catchError((res) => this._handleError(res)),
    );
  }

  deleteCustomCredential(id: number): Observable<boolean> {
    return this._delete(`/v1/credential-net/custom_credentials/${id}`).pipe(
      map((res) => this._handleResponse(res, 'success')),
      catchError((res) => this._handleError(res)),
    );
  }

  // Evidences
  loadEvidenceInsights(id: number): Observable<AccredibleEvidenceItemInsights> {
    return this._get(`/v1/credential-net/evidence_items/${id}`).pipe(
      map((res) => this._handleResponse(res, 'evidence_item')),
      catchError((res) => this._handleError(res)),
    );
  }

  addEvidenceItem(evidenceItem: CuEvidenceItem): Observable<AccredibleEvidenceItem> {
    return this._post(
      `/v1/credential-net/credentials/${evidenceItem.credentialId}/evidence_items`,
      {
        evidence_item: evidenceItem.evidenceItem,
      },
    ).pipe(
      // TODO: Create PR in https://github.com/tjoskar/ng-lazyload-image/issues/129 and if accepted remove delay(1000)
      // delay(1000) is here because the image takes some time to be available on s3, if we try to load it right away we get image failed to load
      delay(1000),
      map((res) => this._handleResponse(res, 'evidence_item')),
      catchError((res) => this._handleError(res)),
    );
  }

  editEvidenceItem(evidenceItem: CuEvidenceItem): Observable<AccredibleEvidenceItem> {
    return this._put(
      `/v1/credential-net/credentials/${evidenceItem.credentialId}/evidence_items/${evidenceItem.evidenceItem.id}`,
      { evidence_item: evidenceItem.evidenceItem },
    ).pipe(
      map((res) => this._handleResponse(res, 'evidence_item')),
      catchError((res) => this._handleError(res)),
    );
  }

  reorderEvidenceItems(
    credentialId: number,
    evidenceItems: LocalEvidenceItem[],
  ): Observable<AccredibleEvidenceItem[]> {
    return this._post(`/v1/credential-net/credentials/${credentialId}/evidence_items_reorder`, {
      credential_id: credentialId,
      evidence_items: evidenceItems.map((item, index) => {
        return {
          id: item.id,
          position: index + 1,
        };
      }),
    }).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  deleteEvidenceItem(credentialId: number, evidenceItemId: number): Observable<number> {
    return this._delete(
      `/v1/credential-net/credentials/${credentialId}/evidence_items/${evidenceItemId}`,
    ).pipe(
      map((res) => this._handleResponse(res, 'evidence_item', 'id')),
      catchError((res) => this._handleError(res)),
    );
  }

  loadPathwaysEnrolment(username: string, credentialId: string): Observable<PathwayEnrolment[]> {
    let url = `/v1/recipient/users/${username}/pathway_enrolments`;
    const searchParams = new URLSearchParams();

    if (credentialId) {
      searchParams.set('credential_id', credentialId);
    }

    url = searchParams.toString() ? `${url}?${searchParams.toString()}` : url;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'pathway_enrolments')),
      catchError((res) => this._handleError(res)),
    );
  }

  // Mock example - '/recommendation.json', true
  loadRecommendation(groupId: number | string): Observable<RecommendedGroup[]> {
    return this._get(`/v1/recipient/groups/${groupId}/recommendations`).pipe(
      map((res) => this._handleResponse(res, 'recommended_groups')),
      catchError((res) => this._handleError(res)),
    );
  }

  signalPurchaseIntent(
    params: PhysicalAwardPurchaseIntent,
  ): Observable<PhysicalAwardPurchaseIntentResponse> {
    return this._post(`/v1/recipient/physical_award_purchase_intents`, params).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  updatePurchaseIntent(
    params: PhysicalAwardBESuccessResponse,
    uniqueIdentifier: string,
  ): Observable<boolean> {
    return this._put(
      `/v1/recipient/physical_award_purchase_intents/${uniqueIdentifier}`,
      params,
    ).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }
}
