import { PathwayEnrolment } from '@accredible-frontend-v2/models';
import { AccredibleToastService } from '@accredible-frontend-v2/new-components/toast';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as CredentialsActions from './credentials.actions';
import { CredentialsState } from './credentials.reducer';
import { selectCredentialsState } from './credentials.selectors';
import { CredentialsService } from './credentials.service';

@Injectable()
export class CredentialsEffects {
  loadOrganizationSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.loadOrganizationSettings),
      withLatestFrom(this._credentialsStore.select(selectCredentialsState)),
      exhaustMap(() => {
        return this._credentialsService.loadOrganizationSettings().pipe(
          map((organizationSettings) =>
            CredentialsActions.loadOrganizationSettingsSuccess({ organizationSettings }),
          ),
          catchError((error) => of(CredentialsActions.loadOrganizationSettingsFailure({ error }))),
        );
      }),
    ),
  );

  loadPublicProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.loadPublicProfile),
      withLatestFrom(this._credentialsStore.select(selectCredentialsState)),
      filter(
        ([action, state]) =>
          !state.publicProfile.username || state.publicProfile.username !== action.username,
      ),
      exhaustMap(([action]) =>
        this._credentialsService.loadPublicProfile(action.username).pipe(
          map((publicProfile) =>
            CredentialsActions.loadPublicProfileSuccess({
              publicProfile,
              pageSize: action.pageSize,
            }),
          ),
          catchError((error) => of(CredentialsActions.loadPublicProfileFailure({ error }))),
        ),
      ),
    ),
  );

  loadPrivateProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.loadPrivateProfile),
      withLatestFrom(this._credentialsStore.select(selectCredentialsState)),
      filter(
        ([action, state]) =>
          !state.privateProfile.username || state.privateProfile.username !== action.username,
      ),
      exhaustMap(([action]) =>
        this._credentialsService.loadPrivateProfile(action.userId).pipe(
          map((privateProfile) =>
            CredentialsActions.loadPrivateProfileSuccess({
              privateProfile,
              pageSize: action.pageSize,
            }),
          ),
          catchError((error) => of(CredentialsActions.loadPrivateProfileFailure({ error }))),
        ),
      ),
    ),
  );

  loadCredential$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.loadCredential),
      withLatestFrom(this._credentialsStore.select(selectCredentialsState)),
      filter(
        ([action, state]) =>
          // Don't make the request if the idOrUuid is the same
          state.credential.id !== action.idOrUuid && state.credential.uuid !== action.idOrUuid,
      ),
      exhaustMap(([action]) =>
        this._credentialsService.loadCredential(action.idOrUuid, action.privateKey).pipe(
          map((credential) => {
            // Load credential user
            this._credentialsStore.dispatch(
              CredentialsActions.loadCredentialUser({ username: credential.username }),
            );

            // Load pathway enrolments
            if (credential.issuer.pathways_enabled) {
              this._credentialsStore.dispatch(
                CredentialsActions.loadPathwayEnrolment({
                  username: credential.username,
                  credentialId: action.idOrUuid.toString(),
                }),
              );
            }

            // Load all evidence insights
            credential.evidence_items.forEach((item) => {
              this._credentialsStore.dispatch(
                CredentialsActions.loadEvidenceInsights({ id: item.id }),
              );
            });

            return CredentialsActions.loadCredentialSuccess({ credential });
          }),
          catchError((error) => of(CredentialsActions.loadCredentialFailure({ error }))),
        ),
      ),
    ),
  );

  loadPathwayEnrolments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.loadPathwayEnrolment),
      concatMap(({ username, credentialId }) =>
        this._credentialsService.loadPathwaysEnrolment(username, credentialId).pipe(
          map((pathwayEnrolments: PathwayEnrolment[]) =>
            CredentialsActions.loadPathwayEnrolmentSuccess({ pathwayEnrolments }),
          ),
          catchError((error) => of(CredentialsActions.loadPathwayEnrolmentFailure({ error }))),
        ),
      ),
    ),
  );

  loadCredentialUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.loadCredentialUser),
      concatMap((action) =>
        this._credentialsService.loadCredentialUser(action.username).pipe(
          map((credentialUser) => CredentialsActions.loadCredentialUserSuccess({ credentialUser })),
          catchError((error) => of(CredentialsActions.loadCredentialUserFailure({ error }))),
        ),
      ),
    ),
  );

  updateCredentialPrivacy = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.updateCredentialPrivacy),
      switchMap((action) => {
        const credentialId = action.credentialId;
        const credentialUrl = action.credentialUrl;
        const isPrivate = action.isPrivate;
        return this._credentialsService
          .updateCredentialPrivacy(action.credentialId, action.isPrivate)
          .pipe(
            map(({ private_key, url }) =>
              CredentialsActions.updateCredentialPrivacySuccess({
                credentialId,
                credentialUrl: url || credentialUrl,
                isPrivate,
                privateKey: private_key,
              }),
            ),
            catchError((error) => of(CredentialsActions.updateCredentialPrivacyFailure({ error }))),
          );
      }),
    ),
  );

  requestRecipientNameChange$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.requestRecipientNameChange),
      switchMap((action) =>
        this._credentialsService
          .requestRecipientNameChange(action.credentialId, action.updatedName)
          .pipe(
            map((res) =>
              CredentialsActions.requestRecipientNameChangeSuccess({
                nameChangeResponse: res.body.recipient_name_change_request,
                statusCode: res.status,
              }),
            ),
            catchError((error) =>
              of(CredentialsActions.requestRecipientNameChangeFailure({ error })),
            ),
          ),
      ),
    ),
  );

  // Custom Credential
  loadCustomCredential$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.loadCustomCredential),
      exhaustMap((action) =>
        this._credentialsService.loadCustomCredential(action.uuid).pipe(
          map((customCredential) => {
            // Load custom credential user
            this._credentialsStore.dispatch(
              CredentialsActions.loadCredentialUser({ username: action.username }),
            );

            return CredentialsActions.loadCustomCredentialSuccess({
              customCredential,
              username: action.username,
            });
          }),
          catchError((error) => of(CredentialsActions.loadCustomCredentialFailure({ error }))),
        ),
      ),
    ),
  );

  addCustomCredential = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.addCustomCredential),
      switchMap((action) =>
        this._credentialsService.addCustomCredential(action.customCredential).pipe(
          map((customCredential) =>
            CredentialsActions.addCustomCredentialSuccess({ customCredential }),
          ),
          catchError((error) => of(CredentialsActions.addCustomCredentialFailure({ error }))),
        ),
      ),
    ),
  );

  editCustomCredential = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.editCustomCredential),
      switchMap((action) =>
        this._credentialsService.editCustomCredential(action.customCredential).pipe(
          map((customCredential) =>
            CredentialsActions.editCustomCredentialSuccess({ customCredential }),
          ),
          catchError((error) => of(CredentialsActions.editCustomCredentialFailure({ error }))),
        ),
      ),
    ),
  );

  deleteCustomCredential$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.deleteCustomCredential),
      switchMap((action) =>
        this._credentialsService.deleteCustomCredential(action.id).pipe(
          map(() => CredentialsActions.deleteCustomCredentialSuccess({ id: action.id })),
          catchError((error) => of(CredentialsActions.deleteCustomCredentialFailure({ error }))),
        ),
      ),
    ),
  );

  // Evidences
  loadEvidenceInsights$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.loadEvidenceInsights),
      concatMap((action) =>
        this._credentialsService.loadEvidenceInsights(action.id).pipe(
          map((evidenceInsights) =>
            CredentialsActions.loadEvidenceInsightsSuccess({ evidenceInsights }),
          ),
          catchError((error) => of(CredentialsActions.loadEvidenceInsightsFailure({ error }))),
        ),
      ),
    ),
  );

  addEvidenceItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.addEvidenceItem),
      switchMap((action) =>
        this._credentialsService.addEvidenceItem(action.evidenceItem).pipe(
          map((evidenceItem) => {
            this._credentialsStore.dispatch(
              CredentialsActions.loadEvidenceInsights({ id: evidenceItem.id }),
            );
            return CredentialsActions.addEvidenceItemSuccess({ evidenceItem });
          }),
          catchError((error) => of(CredentialsActions.addEvidenceItemFailure({ error }))),
        ),
      ),
    ),
  );

  editEvidenceItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.editEvidenceItem),
      switchMap((action) =>
        this._credentialsService.editEvidenceItem(action.evidenceItem).pipe(
          map((evidenceItem) => {
            this._credentialsStore.dispatch(
              CredentialsActions.loadEvidenceInsights({ id: evidenceItem.id }),
            );
            return CredentialsActions.editEvidenceItemSuccess({ evidenceItem });
          }),
          catchError((error) => of(CredentialsActions.editEvidenceItemFailure({ error }))),
        ),
      ),
    ),
  );

  reorderEvidenceItems$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.reorderEvidenceItems),
      exhaustMap((action) => {
        const initialOrderedItems = action.initialOrderedItems;
        return this._credentialsService
          .reorderEvidenceItems(action.credentialId, action.orderedEvidenceItems)
          .pipe(
            map(() => CredentialsActions.reorderEvidenceItemsSuccess()),
            catchError((error) =>
              of(
                CredentialsActions.reorderEvidenceItemsFailure({
                  error,
                  initialOrderedItems,
                }),
              ),
            ),
          );
      }),
    ),
  );

  deleteEvidenceItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.deleteEvidenceItem),
      switchMap((action) =>
        this._credentialsService
          .deleteEvidenceItem(action.credentialId, action.evidenceItemId)
          .pipe(
            map((evidenceItemId) =>
              CredentialsActions.deleteEvidenceItemSuccess({ evidenceItemId }),
            ),
            catchError((error) => of(CredentialsActions.deleteEvidenceItemFailure({ error }))),
          ),
      ),
    ),
  );

  showDeleteEvidenceItemSuccessToast$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(CredentialsActions.deleteEvidenceItemSuccess),
        tap(() => {
          this._toast.success(this._language.translate('credential.evidence.delete.success'));
        }),
      );
    },
    { dispatch: false },
  );

  showDeleteEvidenceItemFailureToast$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(CredentialsActions.deleteEvidenceItemFailure),
        tap(() => {
          this._toast.error(this._language.translate('credential.evidence.delete.failure'));
        }),
      );
    },
    { dispatch: false },
  );

  // Recommendation
  loadRecommendation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.loadRecommendations),
      concatMap((action) =>
        this._credentialsService.loadRecommendation(action.groupId).pipe(
          map((course_recommendations) =>
            CredentialsActions.loadRecommendationsSuccess({ course_recommendations }),
          ),
          catchError((error) => of(CredentialsActions.loadRecommendationsFailure({ error }))),
        ),
      ),
    ),
  );

  updatePurchaseIntent = createEffect(() =>
    this._actions$.pipe(
      ofType(CredentialsActions.updatePurchaseIntent),
      concatMap((action) =>
        this._credentialsService.updatePurchaseIntent(action.params, action.uniqueIdentifier).pipe(
          map((success) => CredentialsActions.updatePurchaseIntentSuccess({ success: success })),
          catchError((error) => of(CredentialsActions.updatePurchaseIntentFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _credentialsStore: Store<CredentialsState>,
    private readonly _credentialsService: CredentialsService,
    private readonly _toast: AccredibleToastService,
    private readonly _language: AccredibleLanguageService,
  ) {}
}
