export class AccredibleTruncateHelper {
  static truncate(value: string, maxCharacters: number, customEllipsis?: string): string {
    if (value) {
      const indexOfFirstSpaceAfterMaxCharacters = value.indexOf(' ', maxCharacters);
      const ellipsis = customEllipsis || ' ...';
      return indexOfFirstSpaceAfterMaxCharacters === -1
        ? value
        : value.substring(0, indexOfFirstSpaceAfterMaxCharacters).concat(ellipsis);
    }

    return value;
  }
}
