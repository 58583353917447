import { environment } from '@accredible-frontend-v2/envs';
import {
  AccredibleOpenSearchApiParsedResponse,
  AccredibleOpenSearchApiResponse,
  AccrediblePageMeta,
} from '@accredible-frontend-v2/models';
import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import { getXSignature } from '@accredible-frontend-v2/utils/api-signature';
import { getDateNowInSeconds } from '@accredible-frontend-v2/utils/date-helper';
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AccredibleAPIMockData } from './api.model';

const API_X_SIGNATURE = 'X-Signature';
const API_X_TIMESTAMP = 'X-Timestamp';

@Injectable()
export class AccredibleApiService {
  apiUrl = environment.apiUrl;
  mockLocalUrl = './assets/mock-json';

  http = inject(HttpClient);
  router = inject(Router);
  location = inject(Location);
  cookies = inject(AccredibleCookiesService);
  browserStorage = inject(AccredibleBrowserStorageService);

  protected _get(endpoint: string, mock = false): Observable<HttpResponse<any> | any> {
    return !mock
      ? this.http.get(this.apiUrl + endpoint, {
          headers: this._getHeaders('GET', endpoint),
          observe: 'response',
        })
      : this.http.get(this.mockLocalUrl + endpoint);
  }

  protected _post(endpoint: string, body: any, mock = false): Observable<HttpResponse<any> | any> {
    return !mock
      ? this.http.post(this.apiUrl + endpoint, body, {
          headers: this._getHeaders('POST', endpoint),
          observe: 'response',
        })
      : this.http.get(this.mockLocalUrl + endpoint);
  }

  protected _put(endpoint: string, body: any, mock = false): Observable<HttpResponse<any> | any> {
    return !mock
      ? this.http.put(this.apiUrl + endpoint, body, {
          headers: this._getHeaders('PUT', endpoint),
          observe: 'response',
        })
      : this.http.get(this.mockLocalUrl + endpoint);
  }

  protected _delete(
    endpoint: string,
    body?: any,
    mock = false,
  ): Observable<HttpResponse<any> | any> {
    return !mock
      ? this.http.delete(this.apiUrl + endpoint, {
          headers: this._getHeaders('DELETE', endpoint),
          body,
          observe: 'response',
        })
      : this.http.get(this.mockLocalUrl + endpoint);
  }

  protected _handleResponse(res: any, dataKey1?: string, dataKey2?: string): any {
    return dataKey2 && dataKey1
      ? res.body[dataKey1][dataKey2]
      : dataKey1
      ? res.body[dataKey1]
      : res.body;
  }

  protected _handleOpenSearchResponse<T>(
    res: AccredibleOpenSearchApiResponse<T>,
  ): AccredibleOpenSearchApiParsedResponse<T> {
    const currentPage = Math.ceil(res.meta.from / res.meta.size) + 1;
    const totalPages = Math.ceil(res.meta.count / res.meta.size);
    return {
      meta: <AccrediblePageMeta>{
        current_page: currentPage,
        total_pages: totalPages,
        next_page: currentPage < totalPages ? currentPage + 1 : null,
        prev_page: currentPage > 1 ? currentPage - 1 : null,
        page_size: res.meta.size,
        total_count: res.meta.count,
      },
      hits: res.hits.map((hit) => hit['_source']) as T[],
      aggs: res.aggs,
      diagnostics: res.diagnostics,
    };
  }

  protected _handleError(
    res: HttpErrorResponse,
    propagate404 = false,
    mockData?: AccredibleAPIMockData,
  ): Observable<any> {
    switch (res.status) {
      case 400:
      case 409:
      case 422:
      case 500:
      case 503:
        return throwError(res.error);

      case 401:
        // 401 cases should be always handled in each specific api service
        break;

      case 404:
        // TODO: Ask API team to create an endpoint that always returns a specific status code for testing mock data
        if (mockData) {
          return of(this._handleResponse(mockData.res, mockData.dataKey1, mockData.dataKey2));
        } else if (propagate404) {
          const currentRoute = this.router.url;
          this.router.navigate(['404'], { replaceUrl: true }).then(() => {
            this.location.replaceState(currentRoute);
          });
        }
        break;

      default:
        if (mockData) {
          return of(this._handleResponse(mockData.res, mockData.dataKey1, mockData.dataKey2));
        }
    }

    return throwError(() => new Error(res.message));
  }

  /**
   * @see getXSignature
   * to understand the logic of X-Signature and X-Timestamp
   */
  protected _getHeaders(method: string, endpoint: string): { [header: string]: string } {
    const signature = getXSignature(method, endpoint);
    const dateNowInSeconds = getDateNowInSeconds();

    if (this._getSessionToken()) {
      return {
        Authorization: 'Bearer ' + this._getSessionToken(),
        [API_X_SIGNATURE]: signature,
        [API_X_TIMESTAMP]: dateNowInSeconds,
      };
    }

    return {
      [API_X_SIGNATURE]: signature,
      [API_X_TIMESTAMP]: dateNowInSeconds,
    };
  }

  protected _getSessionToken(): string {
    // If we are inside an iframe we use localstorage instead of cookies
    // This happens when you're inside an iframe and try to open a new tab, so you don't lose the authentication
    if (this.cookies.check(AccountsRedirectionKey.SESSION_TOKEN_COOKIE)) {
      return this.cookies.get(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
    }

    return this.browserStorage.get(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
  }
}
