import { environment } from '@accredible-frontend-v2/envs';

export const addGoogleTagManager = (tagManager = 'GTM-MQ86D29'): void => {
  if (environment.type === 'production' && location.hostname !== 'localhost') {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    scriptTag.innerHTML = `(
      function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      }
    )(window,document,'script','dataLayer','${tagManager}');`;

    document.head.insertBefore(scriptTag, document.head.firstChild);

    const noscriptTag = document.createElement('noscript');
    const iframeTag = document.createElement('iframe');
    iframeTag.src = `https://www.googletagmanager.com/ns.html?id=${tagManager}`;
    iframeTag.width = '0';
    iframeTag.height = '0';
    iframeTag.style.display = 'none';
    iframeTag.style.visibility = 'hidden';
    noscriptTag.appendChild(iframeTag);

    document.body.insertBefore(noscriptTag, document.body.firstChild);
  }
};

interface GTMData {
  event: string;

  [key: string]: unknown;
}

export const gtmDataLayerPushTag = (data: GTMData): void => {
  const gtmDataLayer = (<any>window).dataLayer || [];
  gtmDataLayer.push(data);
};
