import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  myLearningPathwayEnrolmentsFeatureKey,
  MyLearningPathwayEnrolmentsState,
} from './my-learning-pathway-enrolments.reducer';

export const selectMyLearningPathwayEnrolmentsState =
  createFeatureSelector<MyLearningPathwayEnrolmentsState>(myLearningPathwayEnrolmentsFeatureKey);

export const selectMyLearningPathwayEnrolmentsPageData = createSelector(
  selectMyLearningPathwayEnrolmentsState,
  (state) => state.myLearningPathwayEnrolmentsPageData,
);

export const selectMyLearningFetchedPageParams = createSelector(
  selectMyLearningPathwayEnrolmentsState,
  (state) => state.pageParams,
);
