import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccredibleBrowserHistoryService {
  private _history: string[] = [];

  constructor(private _router: Router) {
    this._init();
  }

  getPreviousUrl(): string {
    return this._history[this._history.length - 2];
  }

  private _init(): void {
    this._router.events
      .pipe(filter((event: Event | RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this._history.push(event.url);
      });
  }
}
