import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';

const LAX = 'Lax';

@Injectable({
  providedIn: 'root',
})
export class AccredibleCookiesService {
  set(name: string, value: string, expires?: number | Date): void {
    if (WindowHelper.isInIframe()) {
      this._browserStorageService.set(name, value);
    } else if (WindowHelper.isLocalhost()) {
      // Safari doesn't save the cookie on localhost if the secure flag (the sixth parameter) is set
      // We are explicitly setting the sameSite to 'Lax' to avoid this issue - https://github.com/stevermeister/ngx-cookie-service/issues/98
      this._cookieService.set(name, value, expires, '/', undefined, undefined, LAX);
    } else {
      this._cookieService.set(name, value, expires, '/', undefined, true, LAX);
    }
  }

  constructor(
    private readonly _cookieService: CookieService,
    private readonly _browserStorageService: AccredibleBrowserStorageService,
    private readonly _deviceDetectorService: DeviceDetectorService,
  ) {}

  check(name: string): boolean {
    if (WindowHelper.isInIframe()) {
      return !!this._browserStorageService.get(name);
    }

    return this._cookieService.check(name);
  }

  get(name: string): string {
    if (WindowHelper.isInIframe()) {
      return this._browserStorageService.get(name);
    }
    return this._cookieService.get(name);
  }

  delete(name: string): void {
    if (WindowHelper.isInIframe()) {
      this._browserStorageService.delete(name);
    }
    this._cookieService.delete(name, '/');
  }

  /** TODO: We have replaced the use of this method with WindowHelper.isInIframe as the support for cookies within iframes continues to decline. This method could be removed entirely.
   * Cookie support within iframes does not work on Safari firefox or Chromium browsers (Chrome, Opera, edge, etc) incognito mode (with default settings as of Chrome 83 - https://venturebeat.com/2020/05/19/google-chrome-83/).
   * When inside an iframe in Safari cookies do not get saved due to Apple restrictions,
   * our workaround at the moment is to use localStorage instead.
   * Maybe this can be improved to actually use cookies (needs research).
   */
  // private _isInIframeAndSafari(): boolean {
  //   return WindowHelper.isInIframe() && this._deviceDetectorService.browser === 'Safari';
  // }
}
