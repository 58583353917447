import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { AccredibleCheckboxInputComponent } from './checkbox-input.component';

const MATERIAL_MODULES = [MatCheckboxModule];

@NgModule({
  imports: [ReactiveFormsModule, ...MATERIAL_MODULES],
  exports: [AccredibleCheckboxInputComponent],
  declarations: [AccredibleCheckboxInputComponent],
  providers: [],
})
export class AccredibleCheckboxInputComponentModule {}
